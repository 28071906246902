import React, { useEffect, useState } from "react";
import "./Adminlistuser.css";
import { FaPen } from "react-icons/fa";
import {FaUserCircle} from "react-icons/fa";
import airpod from "./Images/adminlist_a_airpods.webp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Adminloader from "./Adminloader";
import config from "../config.json";

export default function Adminlist({ handleProfileeditClick }) {
  const baseurl=config.url;
  const [userarray, setUserarray] = useState([]);
  const [user, setUser] = useState("");
  const [show,setShow]=useState(false);
  
  useEffect(()=>{
    setShow(true);
  const URL =  baseurl+"adminuserfetch";
  const Request = {};
  const Headers = {};
  axios.post(URL, Request, Headers)
    .then((res) => {
      setShow(false);
      console.log("This is the output" + JSON.stringify(res.data))
      setUserarray(res.data);
      
    })
    .catch()
  },[])
  
  return (
    <>
    <Adminloader show={show}/>
      <div className="adminlistuser">
        <div className="adminlistuser_header">
          <div className="adminlistuser_heading">
            <label className="adminlistuser_heading_img">Image User</label>
            <label className="adminlistuser_heading_producer">First Name</label>
            <label className="adminlistuser_heading_product">Last Name</label>
            <label className="adminlistuser_heading_id">User Type</label>
            <label className="adminlistuser_heading_cat">Email</label>
            <label className="adminlistuser_heading_link">Phone number</label>
            <label className="adminuserlist_heading_cost">ID</label>
            {/* <label className="adminlist_heading_extra">Extra</label>
            <label className="adminlist_heading_price">Price</label>
            <label className="adminlist_heading_priority">Priority</label> */}
            <label className="adminlistuser_heading_edit">Edit</label>
          </div>
        </div>
        <div className="adminlistuser_middle">
          <Singleadminlist
            image={airpod}
            handleProfileeditClick={handleProfileeditClick}
            userarray={userarray}
          />
        </div>
        <div className="adminlistuser_end">
          <button>Load More Products</button>
        </div>
      </div>
    </>
  );
}

function Singleadminlist({ image,handleProfileeditClick,userarray }) {
  return (
    <>
      {
        userarray.map((item, index) => {
          return (
            <>
              <div className="singleadminlistuser">
                <div className="singleadminlistuser_row">
                  <div className="singleadminlistuser_row_img">
                    <input type="radio"></input>
                    <div className="singleadminlistuser_row_img_icon">
                    <FaUserCircle style={{fontSize:'30px',color:'#D2D7E3'}}/>
                      {/* <img src={image} width={30} height={30} /> */}
                    </div>
                  </div>
                  <label className="singleadminlistuser_row_producer">{item.firstname}</label>
                  <label className="singleadminlistuser_row_product">{item.lastname}</label>
                  <label className="singleadminlistuser_row_id">{item.usrtype}</label>
                  <label className="singleadminlistuser_row_cat">{item.email}</label>
                  <label className="singleadminlistuser_row_link">{item.phoneno}</label>
                  <label className="singleadminlistuser_row_cost">{item.id}</label>
                  {/* <label className="singleadminlistuser_row_extra"></label>
                  <label className="singleadminlistuser_row_price"></label>
                  <label className="singleadminlistuser_row_priority"></label> */}
                  <label className="singleadminlistuser_row_edit" ><FaPen style={{ color: '#D2D7E3' }} onClick={(e) => { handleProfileeditClick(e,item) }}  /></label>
                </div>
              </div>
            </>
          )

        })

      }

    </>
  );
}
