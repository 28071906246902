import "../style.css";
import AdminLogin from "../Components/AdminLogin";
import { useNavigate } from "react-router-dom";
import "./Adminloginpage.css";
export default function Adminloginpage() {
    // const navigate = useNavigate();
    // const handleLoginClick = (e) => {
    //     e.preventDefault();
        
    return (
        <>
            <div>
                <AdminLogin/>
            </div>
        </>
    )
}