import config from "../config.json";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Adminmenubar.css";

export default function Adminmenubar() {
    const navigate=useNavigate();
    const [array, setArray] = useState(["Dashboard", "Product List", "Order List", "User List", "Banner", "Settings", "Category List", "Subcategory List", "Style List", "Weartype List", "Metal List"])
  
  const handlemenuClick=(e,indx)=>{
    console.log("menu indx==>"+indx);
    if(indx=='0'){
      navigate("/admindashboardpage");
    }else if(indx=='1'){
      navigate("/adminproductlistpage")
    }else if(indx=='2'){
      navigate("/adminorderlistpage")
    }else if(indx=='3'){
      navigate("/adminuserlistpage")
    }else if(indx=='4'){
      navigate("/adminsliderpage")
    }else if(indx=='5'){
      navigate("/admingoldratepage")
    }else if(indx=='6'){
      navigate("/admincategorylistpage")
    }else if(indx=='7'){
      navigate("/adminsubcategorylistpage")
    }else if(indx=='8'){
      navigate("/adminstylelistpage")
    }else if(indx=='9'){
      navigate("/adminweartypelistpage")
    }else if(indx=='10'){
      navigate("/adminmetallistpage")
    }
  }
    return (
        <>
            <div className="adminmenubar">
                <ul className="adminmenubar_ul">
                    {array.map((itm, indx) => {
                        return (
                            <li
                                className="adminmenubar_li"
                                onClick={(e) => handlemenuClick(e,indx)}
                            >
                                <label
                                    className="adminmenubar_title"
                                >
                                    {itm}
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    )
}