import React, { useEffect, useState } from "react";
import "./Adminlistproduct.css";
import { FaPen } from "react-icons/fa";
import airpod from "./Images/earring_g.jpg";
import axios from "axios";
import config from "../config.json";
import Adminloader from "./Adminloader";

export default function Adminlistproduct({ handleProducteditClick, show, productarray }) {

  const [product, setProduct] = useState("");
  const [fetchImage, setFetchImage] = useState([]);


  return (
    <>
      <div className="adminlistproduct">
        <div className="adminlistproduct_header">
          <div className="adminlistproduct_heading">
            <label className="adminlistproduct_heading_img">
              id
            </label>
            <label className="adminlistproduct_heading_producer">
              Product Name
            </label>
            <label className="adminlistproduct_heading_product">Code</label>
            <label className="adminlistproduct_heading_id">Color</label>
            <label className="adminlistproduct_heading_cat">Category</label>
            <label className="adminlistproduct_heading_link">Style</label>
            <label className="adminlistproduct_heading_cost">Type</label>
            <label className="adminlistproduct_heading_extra">Metal</label>
            <label className="adminlistproduct_heading_price">Price</label>
            <label className="adminlistproduct_heading_priority">Weight</label>
            {/* <label className="adminlistproduct_heading_flag">
              Featured Product
            </label> */}
            <label className="adminlistproduct_heading_edit">Edit</label>
          </div>
        </div>
        <div className="adminlistproduct_middle">
          <Adminloader show={show} />
          {/* {fetchImage.map()} */}
          <Singleadminlist
            image={airpod}
            productarray={productarray}
            handleProducteditClick={handleProducteditClick}
          />
        </div>
        <div className="adminlistproduct_end">
          <button>Load More Products</button>
        </div>
      </div>
    </>
  );
}
function Singleadminlist({ handleProducteditClick, productarray, image }) {
  const baseurl = config.url;
  const [featuredproductarray, setFeaturedproductarray] = useState([]);
  const [featuredproduct, setFeaturedproduct] = useState("");
  // const[productid,setProductid]=useState("");
  function handlefeaturedproductClick(e, item) {
    // setProductid(id)
    var id = item.id;
    var URL = baseurl + "adminfeaturedproduct";
    var Request = { id: id };
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        // console.log("featured product list");
        setFeaturedproductarray(res.data);
      })
      .catch();
  }
  return (
    <>
      {productarray.map((item, index) => {
        return (
          <>
            <div className="singleadminlistproduct">
              <div className="singleadminlistproduct_row">
                <div className="singleadminlistproduct_row_img">
                  <label>{item.id}</label>
                  {/* <input type="radio"></input> */}
                  {/* <div>
                    <img src={image} width={30} height={30} />
                  </div> */}
                </div>
                <label className="singleadminlistproduct_row_producer">
                  {item.prdname}
                </label>
                <label className="singleadminlistproduct_row_product">
                  {item.prdcode}
                </label>
                <label className="singleadminlistproduct_row_id">
                  {item.color}
                </label>
                <label className="singleadminlistproduct_row_cat">
                  {item.prdcategory}
                </label>
                <label className="singleadminlistproduct_row_link">
                  {item.prdstyle}
                </label>
                <label className="singleadminlistproduct_row_cost">
                  {item.prdtype}
                </label>
                <label className="singleadminlistproduct_row_extra">
                  {item.prdmetal}
                </label>
                <label className="singleadminlistproduct_row_price">
                  {item.price}
                </label>
                <label className="singleadminlistproduct_row_priority">
                  {item.weight}
                </label>
                {/* <input
                  className="singleadminlistproduct_row_flag"
                  type="checkbox"
                  onChange={(e) => {
                    handlefeaturedproductClick(e, item);
                  }}
                ></input> */}
                <FaPen
                  className="singleadminlistproduct_row_edit"
                  style={{ color: "#D2D7E3" }}
                  onClick={(e) => {
                    handleProducteditClick(e, item.id);
                  }}
                />
              </div>
            </div>
          </>
        );
      })}
    </>
  );
}
