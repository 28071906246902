import "./AdminUploadUrl.css";
import "./AdminImages.css";
import "./AdminVideo.css";
import "./Adminaddsavetitle.css";
import "./Admineditsubtitle.css";
import "./Adminslider.css";
import { IoCloseSharp } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import { Navigate, useNavigate } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";
import AdminUserProfile_Women1 from "./Images/AdminUserProfile_Women1.png";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./AdminUploadFile.css";
import { MdUpload } from "react-icons/md";
import { useEffect, useState } from "react";
import jewellery from "./Images/Adminvideo_jewellery.jpg";
import config from "../config.json";
import Admineditsubtitle from "./Admineditsubtitle";
import Adminloader from "./Adminloader";


function Adminslider() {
    const navigate = useNavigate();
    const baseurl = config.url;
    const [show, setShow] = useState(false);
    const [slide, setSlide] = useState([]);
    const [selectedImages2, setSelectedImage2] = useState([]);
    const [selectedImages1, setSelectedImage1] = useState([]);
    const [selectedImages3, setSelectedImage3] = useState([]);
    const [selectedImages4, setSelectedImage4] = useState([]);
    const [selectedImages5, setSelectedImage5] = useState([]);

    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const [file3, setFile3] = useState("");
    const [file4, setFile4] = useState("");
    const [file5, setFile5] = useState("");
    function removeImage1(id) {
        const newList = selectedImages1.filter((l) => l.id !== id);
        setSelectedImage1(newList);
    }
    function removeImage2(id) {
        const newList = selectedImages1.filter((l) => l.id !== id);
        setSelectedImage2(newList);
    }
    function removeImage3(id) {
        const newList = selectedImages1.filter((l) => l.id !== id);
        setSelectedImage3(newList);
    }
    function removeImage4(id) {
        const newList = selectedImages1.filter((l) => l.id !== id);
        setSelectedImage4(newList);
    }
    function removeImage5(id) {
        const newList = selectedImages1.filter((l) => l.id !== id);
        setSelectedImage5(newList);
    }
    const handleUploadChange1 = (e) => {
        if (e.target.files.length != 0 && e.target.files.length <= 1) {
            console.log("image name==>", e.target.files);
            const selectedFiles = e.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            // console.log("Array==>",selectedFilesArray);
            // });
            let reader = new FileReader();
            const imageArray = selectedFilesArray.map((file) => {
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log("image:" + reader.result);
                    console.log("img size==>" + reader.result.length)
                    const imglength = reader.result.length / 1000;
                    if (imglength < 700) {
                        setFile1(reader.result)
                    } else {
                        alert("Image size limit is upto 500kb")
                    }
                };
            });
        }
    };
    const handleUploadChange2 = (e) => {
        if (e.target.files.length != 0 && e.target.files.length <= 1) {
            console.log("image name==>", e.target.files);
            const selectedFiles = e.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            let reader = new FileReader();
            const imageArray = selectedFilesArray.map((file) => {
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log("image:" + reader.result);
                    console.log("img size==>" + reader.result.length)
                    const imglength = reader.result.length / 1000;
                    if (imglength < 700) {
                        setFile2(reader.result)
                    } else {
                        alert("Image size limit is upto 500kb")
                    }
                };
            });
        }
    };
    const handleUploadChange3 = (e) => {
        if (e.target.files.length != 0 && e.target.files.length <= 1) {
            console.log("image name==>", e.target.files);
            const selectedFiles = e.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            let reader = new FileReader();
            const imageArray = selectedFilesArray.map((file) => {
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log("image:" + reader.result);
                    console.log("img size==>" + reader.result.length)
                    const imglength = reader.result.length / 1000;
                    if (imglength < 700) {
                        setFile3(reader.result)
                    } else {
                        alert("Image size limit is upto 500kb")
                    }
                };
            });
        }
    };
    const handleUploadChange4 = (e) => {
        if (e.target.files.length != 0 && e.target.files.length <= 1) {
            console.log("image name==>", e.target.files);
            const selectedFiles = e.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            let reader = new FileReader();
            const imageArray = selectedFilesArray.map((file) => {
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log("image:" + reader.result);
                    console.log("img size==>" + reader.result.length)
                    const imglength = reader.result.length / 1000;
                    if (imglength < 700) {
                        setFile4(reader.result)
                    } else {
                        alert("Image size limit is upto 500kb")
                    }
                };
            });
        }
    };
    const handleUploadChange5 = (e) => {
        if (e.target.files.length != 0 && e.target.files.length <= 1) {
            console.log("image name==>", e.target.files);
            const selectedFiles = e.target.files;
            const selectedFilesArray = Array.from(selectedFiles);
            let reader = new FileReader();
            const imageArray = selectedFilesArray.map((file) => {
                reader.readAsDataURL(file);
                reader.onload = function () {
                    console.log("image:" + reader.result);
                    console.log("img size==>" + reader.result.length)
                    const imglength = reader.result.length / 1000;
                    if (imglength < 700) {
                        setFile5(reader.result)
                    } else {
                        alert("Image size limit is upto 500kb")
                    }
                };
            });
        }
    };
    const handleSaveClick = (e) => {
        setShow(true)
        var url5 = baseurl + "updateallslider";
        var req5 = {
            img1: file1,
            img2: file2,
            img3: file3,
            img4: file4,
            img5: file5,
            // id: "1"
        };
        var header5 = {};
        axios
            .post(url5, req5, header5)
            .then((res) => {
                setShow(false);
                alert("Successfully Updated!!")
                // console.log("Updated main image" + JSON.stringify(res.data));
                // setCurrentupdate1(res.data);
            })
            .catch((err) => {
                alert("Failed Updation!!")

            });
        // var url = baseurl + "updateslider";
        // var req = {
        //     img: file1,
        //     // id: "1"
        // };
        // var header = {};
        // axios
        //     .post(url, req, header)
        //     .then((res) => {
        //         setShow(false);
        //         console.log("Updated main image" + JSON.stringify(res.data));
        //         // setCurrentupdate1(res.data);
        //     })
        //     .catch();

        // var url1 = baseurl + "updateslider1";
        // var req1 = {
        //     img: file2,
        //     // id: "2"
        // };
        // var header1 = {};
        // axios
        //     .post(url1, req1, header1)
        //     .then((res) => {
        //         setShow(false);
        //         console.log("Updated main image1" + JSON.stringify(res.data));
        //         // setCurrentupdate1(res.data);
        //     })
        //     .catch();


        // var url2 = baseurl + "updateslider2";
        // var req2 = {
        //     img: file3,
        //     // id: "6"
        // };
        // var header2 = {};
        // axios
        //     .post(url2, req2, header2)
        //     .then((res) => {
        //         setShow(false);
        //         console.log("Updated main image2" + JSON.stringify(res.data));
        //         setSlide(res.data);
        //     })
        //     .catch();


        // var url3 = baseurl + "updateslider3";
        // var req3 = {
        //     img: file4,
        //     // id: "4"
        // };
        // var header3 = {};
        // axios
        //     .post(url3, req3, header3)
        //     .then((res) => {
        //         setShow(false);
        //         console.log("Updated main image3" + JSON.stringify(res.data));
        //         // setCurrentupdate1(res.data);
        //     })
        //     .catch();

        // var url4 = baseurl + "updateslider4";
        // var req4 = {
        //     img: file5,
        //     // id: "5"
        // };
        // var header4 = {};
        // axios
        //     .post(url4, req4, header4)
        //     .then((res) => {
        //         setShow(false);
        //         console.log("Updated main image4" + JSON.stringify(res.data));
        //         navigate("/admindashboardpage");
        //         // setCurrentupdate1(res.data);
        //     })
        //     .catch();
    }

    useEffect(() => {
        setShow(true)
        const url = baseurl + "adminfetchslider";
        const req = {};
        const header = {};
        axios
            .post(url, req, header)
            .then((res) => {
                setShow(false);
                setFile1(res.data[0].slidimage);
                setFile2(res.data[1].slidimage)
                setFile3(res.data[2].slidimage)
                setFile4(res.data[3].slidimage)
                setFile5(res.data[4].slidimage)
            })
            .catch()
    }, [])
    return (
        <>
            <div className="adminslider_loader">
                <Adminloader show={show} setShow={setShow} />
            </div>

            <div className="adminslider_outer">
                <div className="adminslider">
                    <div className="adminslider_cont1">
                        <div className="adminsaveitle_Titlemenu">
                            <div className="adminsaveitle_Titlemenu_col1">
                                <div className="adminsaveitle_Titmenu_col1_col1">
                                    <label>
                                        <MdShoppingBasket
                                            style={{
                                                color: "#C3CAD9",
                                                fontsize: 70,
                                                width: 25,
                                                height: 25,
                                                textAlign: "center",
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="adminsaveitle_Titmenu_col1_col2">
                                    <label>Banner</label>
                                </div>
                            </div>
                            <div className="adminsaveitle_Titlemenu_col3">
                                <div className="adminsaveitle_Titmenu_col3_iconbutton">
                                    <MdAddCircle style={{ color: "#C499FF", fontSize: "20px" }} />
                                    <button onClick={(e) => handleSaveClick()} className="adminsaveitle_Titlemenu_col3_button">Save Images</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="adminupload_cont2">
          <Admineditsubtitle selectedTabId={2}></Admineditsubtitle>
        </div> */}
                    <div className="adminslider_cont">
                        <div classname="adminslider_cont_inner">
                            <div className="adminslider_cont_inner_select">
                                <div className="adminslider_cont_inner_select_cont">
                                    <div className="adminslider_cont_inner_select_slider">
                                        <div className="adminslider_cont_inner_select_slider_label">
                                            <label>Select Image </label>
                                            <label>(maximum 500KB allowed)</label>
                                        </div>
                                        <div className="adminslider_cont_inner_select_slider1_button">
                                            <div class="wrapper">
                                                <div class="btnimg"><MdUpload
                                                    style={{ color: "white", fontSize: "1.5rem" }}
                                                /></div>
                                                <input type="file"
                                                    accept="image/*"
                                                    id="contained-button-file1"
                                                    onChange={(e) => handleUploadChange1(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adminslider_cont_inner_review">
                                        <div className="adminslider_cont_inner_review_slider">
                                            <div className="adminslider_cont_inner_review_slider1_header">
                                                <div className="adminslider_cont_inner_review_slider1_header_label">
                                                    <label>Image Preview</label>
                                                </div>
                                                {/* <div className="adminslider_cont_inner_review_slider1_header_close">
                                                <AiFillCloseCircle
                                                    style={{
                                                        color: "#C3CAD9",
                                                        fontsize: 50,
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                // onClick={removeImage1}
                                                />
                                            </div> */}

                                            </div>

                                            <div className="adminslider_cont_inner_review_slider1_body">
                                                <div className="adminslider_cont_inner_review_slider1_body_images">
                                                    {/* {selectedImages1 &&
                                                        selectedImages1.map((image, indx) => {
                                                            return ( */}
                                                    <>
                                                        <div className="AdminVideo_inner_cont_row1_col2">
                                                            {/* <AiFillCloseCircle
                                style={{
                                  color: "#C3CAD9",
                                  fontsize: 50,
                                  width: 20,
                                  height: 20,
                                }}
                                // onClick={() => {
                                //   removeImage(image.id);
                                // }}
                              /> */}
                                                        </div>
                                                        <div className="AdminVideo_outer_video">
                                                            <img
                                                                // key={image.id}
                                                                src={file1}
                                                                className="photo"
                                                                width={200}
                                                            ></img>
                                                            <span>
                                                                <AiFillCloseCircle
                                                                    style={{
                                                                        color: "#C3CAD9",
                                                                        fontsize: 50,
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                    onClick={() => {
                                                                        // removeImage1(image.id);
                                                                    }}
                                                                />
                                                            </span>
                                                            {/* <video src={AdminVideo_1}></video> */}
                                                            {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                                                        </div>
                                                    </>
                                                    {/* );
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adminslider_cont_inner_select_cont">
                                    <div className="adminslider_cont_inner_select_slider">
                                        <div className="adminslider_cont_inner_select_slider_label">
                                            <label>Select Image </label>
                                            <label>(maximum 500KB allowed)</label>                                        </div>
                                        <div className="adminslider_cont_inner_select_slider2_button">
                                            <div class="wrapper">
                                                <div class="btnimg"><MdUpload
                                                    style={{ color: "white", fontSize: "1.5rem" }}
                                                /></div>
                                                <input type="file"
                                                    accept="image/*"
                                                    id="contained-button-file2"
                                                    onChange={(e) => handleUploadChange2(e)}
                                                // multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adminslider_cont_inner_review">
                                        <div className="adminslider_cont_inner_review_slider">
                                            <div className="adminslider_cont_inner_review_slider1_header">
                                                <div className="adminslider_cont_inner_review_slider1_header_label">
                                                    <label>Image Preview</label>
                                                </div>
                                                {/* <div className="adminslider_cont_inner_review_slider1_header_close">
                                                <AiFillCloseCircle
                                                    style={{
                                                        // color: "#C3CAD9",
                                                        // fontsize: 50,
                                                        // width: 20,
                                                        // height: 20,
                                                    }}
                                                // onClick={removeImage1}
                                                />
                                            </div> */}

                                            </div>

                                            <div className="adminslider_cont_inner_review_slider1_body">
                                                <div className="adminslider_cont_inner_review_slider1_body_images">
                                                    {/* {selectedImages2 &&
                                                        selectedImages2.map((image, indx) => {
                                                            return ( */}
                                                    <>
                                                        <div className="AdminVideo_inner_cont_row1_col2">
                                                            {/* <AiFillCloseCircle
                                style={{
                                  color: "#C3CAD9",
                                  fontsize: 50,
                                  width: 20,
                                  height: 20,
                                }}
                                // onClick={() => {
                                //   removeImage(image.id);
                                // }}
                              /> */}
                                                        </div>
                                                        <div className="AdminVideo_outer_video">
                                                            <img
                                                                // key={image.id}
                                                                src={file2}
                                                                className="photo"
                                                                width={200}
                                                            ></img>
                                                            <span>
                                                                <AiFillCloseCircle
                                                                    style={{
                                                                        color: "#C3CAD9",
                                                                        fontsize: 50,
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                    onClick={() => {
                                                                        // removeImage2(image.id);
                                                                    }}
                                                                />
                                                            </span>

                                                            {/* <video src={AdminVideo_1}></video> */}
                                                            {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                                                        </div>
                                                    </>
                                                    {/* );
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adminslider_cont_inner_select_cont">
                                    <div className="adminslider_cont_inner_select_slider">
                                        <div className="adminslider_cont_inner_select_slider_label">
                                            <label>Select Image </label>
                                            <label>(maximum 500KB allowed)</label>                                        </div>
                                        <div className="adminslider_cont_inner_select_slider3_button">
                                            <div class="wrapper">
                                                <div class="btnimg"><MdUpload
                                                    style={{ color: "white", fontSize: "1.5rem" }}
                                                /></div>
                                                <input type="file"
                                                    accept="image/*"
                                                    id="contained-button-file3"
                                                    onChange={(e) => handleUploadChange3(e)}
                                                // multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adminslider_cont_inner_review">
                                        <div className="adminslider_cont_inner_review_slider">
                                            <div className="adminslider_cont_inner_review_slider1_header">
                                                <div className="adminslider_cont_inner_review_slider1_header_label">
                                                    <label>Image Preview</label>
                                                </div>
                                                {/* <div className="adminslider_cont_inner_review_slider1_header_close">
                                                <AiFillCloseCircle
                                                    style={{
                                                        // color: "#C3CAD9",
                                                        // fontsize: 50,
                                                        // width: 20,
                                                        // height: 20,
                                                    }}
                                                // onClick={removeImage1}
                                                />
                                            </div> */}

                                            </div>

                                            <div className="adminslider_cont_inner_review_slider1_body">
                                                <div className="adminslider_cont_inner_review_slider1_body_images">
                                                    {/* {selectedImages3 &&
                                                        selectedImages3.map((image, indx) => {
                                                            return ( */}
                                                    <>
                                                        <div className="AdminVideo_inner_cont_row1_col2">
                                                            {/* <AiFillCloseCircle
                                style={{
                                  color: "#C3CAD9",
                                  fontsize: 50,
                                  width: 20,
                                  height: 20,
                                }}
                                // onClick={() => {
                                //   removeImage(image.id);
                                // }}
                              /> */}
                                                        </div>
                                                        <div className="AdminVideo_outer_video">
                                                            <img
                                                                // key={image.id}
                                                                src={file3}
                                                                className="photo"
                                                                width={200}
                                                            ></img>
                                                            <span>
                                                                <AiFillCloseCircle
                                                                    style={{
                                                                        color: "#C3CAD9",
                                                                        fontsize: 50,
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                    onClick={() => {
                                                                        // removeImage3(image.id);
                                                                    }}
                                                                />
                                                            </span>
                                                            {/* <video src={AdminVideo_1}></video> */}
                                                            {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                                                        </div>
                                                    </>
                                                    {/* );
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adminslider_cont_inner_select_cont">
                                    <div className="adminslider_cont_inner_select_slider">
                                        <div className="adminslider_cont_inner_select_slider_label">
                                            <label>Select Image </label>
                                            <label>(maximum 500KB allowed)</label>                                        </div>
                                        <div className="adminslider_cont_inner_select_slider3_button">
                                            <div class="wrapper">
                                                <div class="btnimg"><MdUpload
                                                    style={{ color: "white", fontSize: "1.5rem" }}
                                                /></div>
                                                <input type="file"
                                                    accept="image/*"
                                                    id="contained-button-file4"
                                                    onChange={(e) => handleUploadChange4(e)}

                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="adminslider_cont_inner_review">
                                        <div className="adminslider_cont_inner_review_slider">
                                            <div className="adminslider_cont_inner_review_slider1_header">
                                                <div className="adminslider_cont_inner_review_slider1_header_label">
                                                    <label>Image Preview</label>
                                                </div>
                                                {/* <div className="adminslider_cont_inner_review_slider1_header_close">
                                                <AiFillCloseCircle
                                                    style={{
                                                        color: "#C3CAD9",
                                                        fontsize: 50,
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                // onClick={removeImage1}
                                                />
                                            </div> */}

                                            </div>

                                            <div className="adminslider_cont_inner_review_slider1_body">
                                                <div className="adminslider_cont_inner_review_slider1_body_images">
                                                    {/* {selectedImages4 &&
                                                        selectedImages4.map((image, indx) => {
                                                            return ( */}
                                                    <>
                                                        <div className="AdminVideo_inner_cont_row1_col2">
                                                            {/* <AiFillCloseCircle
                                style={{
                                  color: "#C3CAD9",
                                  fontsize: 50,
                                  width: 20,
                                  height: 20,
                                }}
                                // onClick={() => {
                                //   removeImage(image.id);
                                // }}
                              /> */}
                                                        </div>
                                                        <div className="AdminVideo_outer_video">
                                                            <img
                                                                // key={image.id}
                                                                src={file4}
                                                                className="photo"
                                                                width={200}
                                                            ></img>
                                                            <span>
                                                                <AiFillCloseCircle
                                                                    style={{
                                                                        color: "#C3CAD9",
                                                                        fontsize: 50,
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                    onClick={() => {
                                                                        // removeImage4(image.id);
                                                                    }}
                                                                />
                                                            </span>
                                                            {/* <video src={AdminVideo_1}></video> */}
                                                            {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                                                        </div>
                                                    </>
                                                    {/* );
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adminslider_cont_inner_select_cont">
                                    <div className="adminslider_cont_inner_select_slider">
                                        <div className="adminslider_cont_inner_select_slider_label">
                                            <label>Select Image </label>
                                            <label>(maximum 500KB allowed)</label>                                        </div>
                                        <div className="adminslider_cont_inner_select_slider4_button">
                                            <div class="wrapper">
                                                <div class="btnimg"><MdUpload
                                                    style={{ color: "white", fontSize: "1.5rem" }}
                                                /></div>
                                                <input type="file"
                                                    accept="image/*"
                                                    id="contained-button-file5"
                                                    onChange={(e) => handleUploadChange5(e)}
                                                // multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="adminslider_cont_inner_review">
                                        <div className="adminslider_cont_inner_review_slider">
                                            <div className="adminslider_cont_inner_review_slider1_header">
                                                <div className="adminslider_cont_inner_review_slider1_header_label">
                                                    <label>Image Preview</label>
                                                </div>
                                                {/* <div className="adminslider_cont_inner_review_slider1_header_close">
                                                <AiFillCloseCircle
                                                    style={{
                                                        color: "#C3CAD9",
                                                        fontsize: 50,
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                // onClick={removeImage1}
                                                />
                                            </div> */}

                                            </div>

                                            <div className="adminslider_cont_inner_review_slider1_body">
                                                <div className="adminslider_cont_inner_review_slider1_body_images">
                                                    {/* {selectedImages5 &&
                                                        selectedImages5.map((image, indx) => {
                                                            return ( */}
                                                    <>
                                                        <div className="AdminVideo_inner_cont_row1_col2">
                                                            {/* <AiFillCloseCircle
                                style={{
                                  color: "#C3CAD9",
                                  fontsize: 50,
                                  width: 20,
                                  height: 20,
                                }}
                                // onClick={() => {
                                //   removeImage(image.id);
                                // }}
                              /> */}
                                                        </div>
                                                        <div className="AdminVideo_outer_video">
                                                            <img
                                                                // key={image.id}
                                                                src={file5}
                                                                className="photo"
                                                                width={200}
                                                            ></img>
                                                            <span>
                                                                <AiFillCloseCircle
                                                                    style={{
                                                                        color: "#C3CAD9",
                                                                        fontsize: 50,
                                                                        width: 20,
                                                                        height: 20,
                                                                    }}
                                                                    onClick={() => {
                                                                        // removeImage5(image.id);
                                                                    }}
                                                                />
                                                            </span>
                                                            {/* <video src={AdminVideo_1}></video> */}
                                                            {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                                                        </div>
                                                    </>
                                                    {/* );
                                                        })} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Adminslider;