import React, { useEffect, useState } from "react";
import "./AdminTitle.css";
import "./Adminorderedittitle.css";
import "./Adminbasicinformation.css";
import "./Adminrelated.css";
import { BsThreeDots } from "react-icons/bs";
import axios from "axios";
import { json, Navigate, useNavigate } from "react-router-dom";
import { hydrate } from "react-dom";
import { IoIosAddCircle } from "react-icons/io";
import monitor from "./Images/ring1.png";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import config from "../config.json";
import Adminloader from "./Adminloader";

export default function Adminbasicinformation() {
  const baseurl = config.url;
  const [orderarray, setOrderarray] = useState([]);
  const [order, setOrder] = useState("");
  const [cdrid, setCdrid] = useState("");
  const [rate, setRate] = useState("");
  const [qty, setQty] = useState("");
  const [createdon, setCreatedon] = useState("");
  const [dtcreatedon, setDtcreatedon] = useState("");
  const [dtdeliveredon, setDtdeliveredon] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [status, setStatus] = useState("");
  const [deliveredon, setDeliveredon] = useState("");
  const [state, setState] = useState("");
  const [usraddress, setUsraddress] = useState("");
  const [updatedorderarray, setUpdatedorderarray] = useState([]);
  const [cartdorder, setCartorder] = useState([]);
  const [cartAmount, setCartAmount] = useState([]);
  const [statusArray, setStatusArray] = useState([]);
  const [updateStatus, setUpdateStatus] = useState("");
  const [totalamount, setTotalAmount] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [show,setShow]=useState(false);
  const navigate = useNavigate();
  var i = localStorage.getItem("Id");
  var h = localStorage.getItem("headerid");
  var u = localStorage.getItem("userid");
  var a = localStorage.getItem("addressid");
  var URL = baseurl + "adminsingleorderfetch1";
  var Request = { id: h };
  var Headers = {};
  useEffect(() => {
    setShow(true);
    axios
      .post(URL, Request, Headers)
      .then((res) => {

        // if (res.data > 0) {

        setOrderarray(res.data);
        setCdrid(res.data[0].hdrid);
        setRate(res.data[0].amount);
        setQty(res.data[0].qty);
        setCreatedon(res.data[0].dtcreatedon);
        setFirstname(res.data[0].firstname);
        setLastname(res.data[0].lastname);
        setStatus(res.data[0].status);
        setDeliveredon(res.data[0].dtdeliveredon);
        setState(res.data[0].state);
        setUsraddress(res.data[0].usraddress);
        setTotalAmount(res.data[0].amount);
        setPhoneno(res.data[0].phoneno);
        // console.log("res.data[0].amount==>"+res.data[0].amount)
        // }
      })
      .catch();
    const url1 = baseurl + "fetchorderprddetails1";
    const req1 = { hid: h };
    const header1 = {};
    axios
      .post(url1, req1, header1)
      .then((res) => {
        setShow(false);
        // if (res.data > 0) {
        setCartorder(res.data);
        // console.log("cart list==>", res.data);
        // }
      })
      .catch((err) => { });

    // const url2 = baseurl + "admintotalcartamount";
    // const req2 = { uid: u };
    // const header2 = {};
    // axios
    //   .post(url2, req2, header2)
    //   .then((res) => {
    //     if (res.data > 0) {
    //       setCartAmount(res.data);
    //     }
    //     // console.log("cart amount==>", res.data);
    //   })
    //   .catch((err) => {});
  }, []);
  function handleOrdereditClick() {
    setShow(true);
    var URL = baseurl + "admineditorder";
    var Request = {
      hdrid: h,
      // usrid: u,
      // adrid: a,
      // price: rate,
      // qty: qty,
      // createdon: createdon,
      status: status,
      deliveredon: deliveredon,
      // ordid: i,
    };
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        setShow(false);
        setUpdatedorderarray(res.data);
        navigate("/Adminorderlistpage");
      })
      .catch();
  }
  const handleStatusClick = () => {
    setShow(false);
    const url = baseurl + "adminupdatestatus";
    const req = { status: updateStatus, hid: h };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setStatusArray(res.data);
        setShow(false);
      })
      .catch((err) => { });
  };
  return (
    <>
    <div className="adminbasicinformation_loader">
      <Adminloader show={show}/>
    </div>
      <div className="adminordereditcom_outer">
        <div className="adminordereditcom">
          <div className="adminordereditcom_row1">
            <div className="AdminTitle_Titlemenu">
              <div className="AdminTitle_Titlemenu_col1">
                <div className="AdminTitle_Titmenu_col1_col1">
                  <icon>
                    <FaClipboardList
                      style={{
                        color: "#C3CAD9",
                        fontsize: 70,
                        width: 20,
                        height: 20,
                        textAlign: "center",
                      }}
                    />
                  </icon>
                </div>
                <div className="AdminTitle_Titmenu_col1_col2">
                  <label>Order{" "} {cdrid}</label>
                </div>
              </div>
              <div className="AdminTitle_Titlemenu_col2">
                <div className="AdminTitle_Titmenu_col2_co1">
                  {/* <label>Order</label> */}
                </div>
                <div className="AdminTitle_Titmenu_col2_co1">
                  {/* <label>Client</label> */}
                </div>
                <div className="AdminTitle_Titmenu_col2_co1">
                  {/* <label>{label4}</label> */}
                </div>
              </div>
              <div className="AdminTitle_Titlemenu_col3">
                <div className="AdminTitle_Titmenu_col3_iconbutton">
                  <MdAddCircle style={{ color: "#C499FF", fontSize: "20px" }} />
                  <button
                    onClick={(e) => {
                      handleOrdereditClick();
                    }}
                  // onClick={(e) => {
                  //   handleAddproductClick();
                  // }}
                  className="AdminTitle_Titlemenu_col3_button"
                  >
                    Save Order
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="adminordereditcom_row2">
            <div className="adminorderedittitle">
              <div className="adminorderedittitle_cont">
                <Adminorderedittitle_list
                  label1="1"
                  label2="Awaiting payment"
                />
                <Adminorderedittitle_list label1="2" label2="In Work" />
                <Adminorderedittitle_list label1="3" label2="Delivery" />
                <Adminorderedittitle_list label1="4" label2="Delivered" />
                <Adminorderedittitle_list label1="5" label2="Failed" />
                <Adminorderedittitle_list label1="6" label2="Done" />
                <Adminorderedittitle_list label1="7" label2="Returns" />
              </div>
            </div>
          </div> */}
          <div className="adminordereditcom_row3">
            {/* <div className="adminorderedit_row3_col1"></div> */}
            <div className="Adminbasicinformation_outer">
              <div className="Adminbasicinformation_outer_row1">
                <label>Basic information</label>
                <BsThreeDots style={{ color: "#C3CAD9" }} />
              </div>
              <div className="Adminbasicinformation_outer_row2">
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Order Number
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder=" o10001"
                    value={cdrid}
                    onChange={(e) => setCdrid(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Rate
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder=" $1500"
                    value={"Rs. "+rate}
                    onChange={(e) => setRate(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Quantity
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder="$300"
                    value={qty}
                    onChange={(e) => setQty(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Order Date
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder=" 22.05.2021"
                    value={createdon}
                    onChange={(e) => setCreatedon(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    User Name
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder="Seth Mendoza"
                    value={firstname+" "+lastname}
                    onChange={(e) => setFirstname(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Order Status
                  </label>
                  {/* <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder="Expectation"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  ></input> */}
                  <select
                    //  className="Adminbasicinformation_outer_row2_row1_label2"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option>Pending</option>
                    <option>Processing</option>
                    <option>Conformed</option>
                    <option>Delivered</option>
                    <option>Rejected</option>
                  </select>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Delivery Date
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type="text"
                    placeholder="Delivery Date"
                    value={deliveredon}
                    onChange={(e) => setDeliveredon(e.target.value)}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    State
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type={"text"}
                    placeholder="United States"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Address
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type={"text"}
                    placeholder="121 Blue Hill Rd,Hopewell Junction, NY 12533"
                    value={usraddress}
                    onChange={(e) => setUsraddress(e.target.value)}
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row1">
                  <label className="Adminbasicinformation_outer_row2_row1_label1">
                    Phonenumber
                  </label>
                  <input
                    className="Adminbasicinformation_outer_row2_row1_label2"
                    type={"text"}
                    placeholder="121 Blue Hill Rd,Hopewell Junction, NY 12533"
                    value={phoneno}
                     
                    readOnly={true}
                  ></input>
                </div>
                <div className="Adminbasicinformation_outer_row2_row3">
                  <input
                    value={updateStatus}
                    placeholder="Awaiting Payment"
                    onChange={(e) => setUpdateStatus(e.target.value)}
                  ></input>
                  <button
                    onClick={(e) => {
                      handleStatusClick();
                    }}
                  >
                    Change Status
                  </button>
                </div>
              </div>
            </div>
            <div className="adminrelated">
              <div className="adminrelated_inner">
                <div className="adminrelated_inner_row1">
                  <label>Order List</label>
                  <BsThreeDots style={{ color: "#C3CAD9" }} />
                </div>
                <div className="adminrelated_inner_header">
                <div className="adminrelated_inner_header4">
                    <label>Id</label>
                  </div>
                  <div className="adminrelated_inner_header1">
                    <label>Product</label>
                  </div>
                  <div className="adminrelated_inner_header2">
                    <label>Quantity</label>
                  </div>
                  <div className="adminrelated_inner_header3">
                    <label>Amount</label>
                  </div>
                </div>
                {cartdorder.map((itm, indx) => {
                  return (
                    <div className="adminrelated_inner_row2">
                      <Singleadminrelated
                        image={itm.image}
                        title={itm.prdname}
                        quantity={itm.qty}
                        price={itm.rate}
                        id={itm.id}
                      />
                      {/* <Singleadminrelated
                    image={monitor}
                    title={"Apple iPad 2022"}
                    quantity="1"
                    price={"$500"}
                  />
                  <Singleadminrelated
                    image={monitor}
                    title={"Apple iPad 2022"}
                    quantity="1"
                    price={"$500"}
                  />
                  <Singleadminrelated
                    image={monitor}
                    title={"Sumsung Galaxy Tab New"}
                    quantity="1"
                    price={"$500"}
                  />
                  <Singleadminrelated
                    image={monitor}
                    title={"Apple Mac Book Pro"}
                    quantity="1"
                    price={"$500"}
                  />
                  <Singleadminrelated
                    image={monitor}
                    title={"Amazon Smart Speaker"}
                    quantity="1"
                    price={"$500"}
                  /> */}
                    </div>
                  );
                })}
                {/* {cartAmount.map((itm, indx) => {
                  return ( */}
                <div className="adminrelated_inner_row3">
                  {/* <button>
              <IoIosAddCircle  style={{color:'#C3CAD9' ,paddingRight:'10px'}}/>
              Add Product
            </button> */}
                  <label>
                    Total Amount <span>:</span>Rs. {totalamount}
                  </label>
                </div>
                {/* );
                })} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Adminorderedittitle_list({ label1, label2 }) {
  return (
    <>
      <div className="adminordertitle_list">
        <div className="adminordertitle_list_label1">
          <label>{label1}</label>
        </div>
        <div className="adminordertitle_list_label2">
          <label>{label2}</label>
        </div>
      </div>
    </>
  );
}

function Singleadminrelated({ id,image, title, quantity, price }) {
  return (
    <>
      <div className="singleadminrelated">
        <div className="singleadminrelated_inner">
        <div className="singleadminrelated_inner_label1">
            <label >{id}</label>
          </div>
          <div className="singleadminrelated_inner_img">
            <img src={image} width={30} height={30} />
            <label className="singleadminrelated_inner_label1">{title}</label>
          </div>
          <div className="singleadminrelated_inner_label3">
            <label >{quantity}</label>
          </div>
          <div className="singleadminrelated_inner_label2">
            <label >{price}</label>
          </div>

        </div>
      </div>
    </>
  );
}
