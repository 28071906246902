
import Adminloginpage from "./Page/Adminloginpage";
import Admindashboardpage from "./Page/Admindashboardpage";
import Adminproductlistpage from "./Page/Adminproductlistpage";
import Adminuserlistpage from "./Page/Adminuserlistpage";
import Adminorderlistpage from "./Page/Adminorderlistpage";
import Adminaddproductpage from "./Page/Adminaddproductpage";
import Admineditproductpage from "./Page/Admineditproductpage";
import Adminordereditpage from "./Page/Adminordereditpage";
import Adminprofileeditpage from "./Page/Adminprofileeditpage";
import Adminproductmediapage from "./Page/Adminproductmediapage";
import Adminaddproductmediapage from "./Page/Adminaddproductmediapage";
import Admingoldratepage from "./Page/Admingoldratepage";
import Adminsliderpage from "./Page/Adminsliderpage";
import Admincategorylistpage from "./Page/Admincategorylistpage";
import Adminsubcategorylistpage from "./Page/Adminsubcategorylistpage";
import Adminstylelistpage from "./Page/Adminstylelistpage";
import Adminweartypelistpage from "./Page/Adminweartypelistpage";
import Adminmetallistpage from "./Page/Adminmetallistpage";
import Admineditcategorypage from "./Page/Admineditcategorypage";
import Adminaddcategorypage from "./Page/Adminaddcategorypage";
import Admineditsubcategorypage from "./Page/Admineditsubcategorypage";
import Admineditstylepage from "./Page/Admineditstylepage";
import Admineditweartypepage from "./Page/Admineditweartypepage";
import Admineditmetalpage from "./Page/Admineditmetalpage";
import Adminaddsubcategorypage from "./Page/Adminaddsubcategorypage";
import Adminaddstylepage from "./Page/Adminaddstylepage";
import Adminaddweartypepage from "./Page/Adminaddweartypepage";
import Adminaddmetalpage from "./Page/Adminaddmetalpage";
/************************* */
 import A from "./Components/Admintasksheet";
 import A1 from "./Components/a1";
 import B from "./Components/b";
 import Admincategorylist from "./Components/Admincategorylist";
//  import AdminUploadUrl from "./Components/AdminUploadUrl";
//  import Adminproductmain from "./Components/Adminproductmain";
import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

const Navigation = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path={"/"} element={<Adminloginpage/>}></Route>
        <Route path={"/admindashboardpage"} element={<Admindashboardpage/>}></Route>
        <Route path={"/adminproductlistpage"} element={<Adminproductlistpage/>}></Route>
        <Route path={"/adminuserlistpage"} element={<Adminuserlistpage/>}></Route>
        <Route path={"/adminorderlistpage"} element={<Adminorderlistpage/>}></Route>
        <Route path={"/adminaddproductpage"} element={<Adminaddproductpage/>}></Route>
        <Route path={"/admineditproductpage"} element={<Admineditproductpage/>}></Route>
        <Route path={"/adminordereditpage"} element={<Adminordereditpage/>}></Route>
        <Route path={"/adminprofileeditpage"} element={<Adminprofileeditpage/>}></Route>
        <Route path={"/adminproductmediapage"} element={<Adminproductmediapage/>}></Route>
        <Route path={"/adminaddproductmediapage"} element={<Adminaddproductmediapage/>}></Route>
        <Route path={"/admingoldratepage"} element={<Admingoldratepage/>}></Route>
        <Route path={"/adminsliderpage"} element={<Adminsliderpage/>}></Route>
        <Route path={"/admincategorylistpage"} element={<Admincategorylistpage/>}></Route>
        <Route path={"/adminsubcategorylistpage"} element={<Adminsubcategorylistpage/>}></Route>
        <Route path={"/adminstylelistpage"} element={<Adminstylelistpage/>}></Route>
        <Route path={"/adminweartypelistpage"} element={<Adminweartypelistpage/>}></Route>
        <Route path={"/adminmetallistpage"} element={<Adminmetallistpage/>}></Route>
        <Route path={"/admineditcategorypage"} element={<Admineditcategorypage/>}></Route>
        <Route path={"/adminaddcategorypage"} element={<Adminaddcategorypage/>}></Route>
        <Route path={"/admineditsubcategorypage"} element={<Admineditsubcategorypage/>}></Route>
        <Route path={"/admineditstylepage"} element={<Admineditstylepage/>}></Route>
        <Route path={"/admineditweartypepage"} element={<Admineditweartypepage/>}></Route>
        <Route path={"/Admineditmetalpage"} element={<Admineditmetalpage/>}></Route>
        <Route path={"/adminaddsubcategorypage"} element={<Adminaddsubcategorypage/>}></Route>
        <Route path={"/adminaddstylepage"} element={<Adminaddstylepage/>}></Route>
        <Route path={"/adminaddweartypepage"} element={<Adminaddweartypepage/>}></Route>
        <Route path={"/Adminaddmetalpage"} element={<Adminaddmetalpage/>}></Route>
       { /*********************************** */}
        <Route path={"/a"} element={<A/>}></Route>
        <Route path={"/b"} element={<B/>}></Route>
        <Route path={"/a1"} element={<A1/>}></Route>
        <Route path={"/admincategorylist"} element={<Admincategorylist/>}></Route>
        {/* <Route path={"/adminuploadurl"} element={<AdminUploadUrl/>}></Route>
        <Route path={"/adminproductmain"} element={<Adminproductmain/>}></Route> */}
      </Routes>
    </HashRouter>
  );
};
export default Navigation;
