import './Adminaddsavetitle.css';
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md"
import axios from 'axios';
import { type } from '@testing-library/user-event/dist/type';
import { useState } from 'react';
import config from "../config.json";

export default function Adminsavetitle({ label,content, handleProducteditClick, checked, setChecked,handleStatusChange,notify,updatedproductarray }) {
    const baseurl = config.url;
    // const[currentupdate,setCurrentupdate]=useState({

    //     "prdname":"Mine Platinum Casual Pendant ",
    //     "prdcode":"UIPD24194PT","color":"Platinum",
    //     "prdcategory":"Pendent","prdstyle":"casual",
    //     "prdtype":"Office wear","prdmetal":"Platinum",
    //     "price":"21861",
    //     "weight":"2.1200",
    //     "prddescription":"Free Shipping In India | Hallmarked jewellery available for sale.",
    //     "prdimg":null,
    //     "deleted":"0",
    //     "featuredflag":"0",
    //     "topcategoryflag":"1"
    // });
    // function handleProducteditClick(){
    //     // var i =localStorage.getItem("Id");
    //     // console.log("get product id" +i)
    //     // var URL="http://localhost:8000/editproduct";
    //     // var Request={prdname:currentupdate.prdname,prdcode:currentupdate.prdcode,color:currentupdate.color,prdcategory:currentupdate.prdcategory,prdstyle:currentupdate.prdstyle,prdtype:currentupdate.prdtype,prdmetal:currentupdate.prdmetal,price:currentupdate.price,weight:currentupdate.weight,prddescription:currentupdate.prddescription,id:i};
    //     // console.log(Request)
    //     // var Headers={};
    //     // axios.post(URL,Request,Headers)
    //     //      .then((res)=>{
    //     //         console.log("Updated Product" + JSON.stringify(res.data))
    //     //         (res.data);
    //     //      })
    //     //      .catch()
    // }


    // const [checked,setChecked]=useState(true);
    

    return (
        <>
            <div className="adminsaveitle_Titlemenu">
                <div className="adminsaveitle_Titlemenu_col1">
                    <div className="adminsaveitle_Titmenu_col1_col1">
                        <label><MdShoppingBasket style={{ color: '#C3CAD9', fontsize: 70, width: 25, height: 25, textAlign: 'center' }} /></label>
                    </div>
                    <div className="adminsaveitle_Titmenu_col1_col2">
                        <label>Products</label>
                    </div>
                </div>
                <div className="adminsaveitle_Titlemenu_col3">
                    <div className='adminsaveitle_Titlemenu_col3_toggle'>
                        <div className="toggle-switch">
                            <input type="checkbox" className="checkbox"
                                name="toggle" id="toggle"
                                checked={checked}
                                // value={status!=1?setChecked(false):""}
                                onChange={(e) => handleStatusChange(e)}
                            />
                            <label className="label" htmlFor="toggle">
                                <span className="inner" />
                                <span className="switch" />
                            </label>
                        </div>
                    </div>
                    <div className="adminsaveitle_Titmenu_col3_iconbutton">
                        <MdAddCircle style={{ color: '#C499FF', fontSize: '20px' }} />
                        <button onClick={(e) => { handleProducteditClick() }} className="adminsaveitle_Titlemenu_col3_button">{content}</button>
                        {/* <div id="snackbar">{label}</div> */}
                         
                    </div>
                </div>
            </div>
        </>
    )
};