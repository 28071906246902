import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminmenubar from "../Components/Adminmenubar";
import Admintasksheet from "../Components/Admintasksheet";
import { useNavigate } from "react-router-dom";
import "./Admindashboardpage.css";

export default function Admindashboardpage() {
    const navigate = useNavigate();
    const handleProductlistClick = () => {
        navigate("/Adminproductlistpage")
    }
    const handleUserlistClick = () => {
        navigate("/Adminuserlistpage")
    }
    const handleProfileeditClick = () => {
        navigate("/Adminprofileeditpage")
    }
    const handleDashboardClick = () => {
        navigate("/Admindashboardpage")
    }
    const handleOrderlistClick = () => {
        navigate("/Adminorderlistpage")
    }
    const handleOrdereditClick = () => {
        navigate("/Adminordereditpage")
    }
    const handleProducteditClick = () => {
        navigate("/Admineditproductpage")
    }
    const handlegoldrateClick = () => {
        navigate("/Admingoldratepage");
    }
    const handleslidereditClick = () => {
        navigate("/Adminsliderpage");
    }
    return (
        <>
            <div className="admindashboard">
                <div className="admindashboard_col1">
                    <Admintopbar
                        title={"Dashboard"}
                    />
                    {/* <Adminmenubar/> */}
                </div>
                <div className="admindashboard_col2">
                    <div className="admindashboard_col2_col1">
                        <AdminLeftBar
                            handleProductlistClick={handleProductlistClick}
                            handleUserlistClick={handleUserlistClick}
                            handleProfileeditClick={handleProfileeditClick}
                            handleDashboardClick={handleDashboardClick}
                            handleOrderlistClick={handleOrderlistClick}
                            handleOrdereditClick={handleOrdereditClick}
                            handleProducteditClick={handleProducteditClick}
                            handlegoldrateClick={handlegoldrateClick}
                            handleslidereditClick={handleslidereditClick}
                        />
                    </div>
                    <div className="admindashboard_col2_col2">
                        <Admintasksheet/>
                    </div>
                </div>
            </div>
        </>
    )
}