import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import AdminTitle from "../Components/AdminTitle";
import Adminsubtitle from "../Components/Adminsubtitle";
import { MdShoppingBasket } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./Adminproductlistpage.css";
import React, { useState, useEffect } from "react";
import Adminlistproduct from "../Components/Adminlistproduct";
import Adminsubtitlelist from "../Components/Adminsubtitlelist";
import axios from "axios";
import config from "../config.json";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminproductlistpage() {
  const navigate = useNavigate();
  const baseurl = config.url;
  const [productarray, setProductarray] = useState([]);
  const handleAddproductClick = () => {
    navigate("/Adminaddproductpage");
  };
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage");
  };

  const handleProducteditClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("prdid", id);
    navigate("/Admineditproductpage");
    console.log("prd id(prd list)==>", id);
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true)
    var URL = baseurl + "adminproductfetch";
    var Request = {};
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        //  let b=atob(res.data[0].image);
        // console.log("decoded==>",atob(res.data[0].image));
        setShow(false);
        setProductarray(res.data);

      })
      .catch();

  }, []);


  const [tabMenus, setTabMenus] = useState([
    {
      id: 1,
      name: "All",
      selected: true,
    },
    {
      id: 2,
      name: "Active",
      selected: false,
    },
    {
      id: 3,
      name: "InActive",
      selected: false,
    },
  ]);

  const onTabClicked = (menu, indx) => {
    setShow(true);
    let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[indx].selected = true;
    setTabMenus(temp);
    const url = baseurl + "adminproductmenu";
    const req = { status: menu.name };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        setProductarray(res.data);
        console.log("menu name==>" + res.data);
        // window.location.reload();
      })
      .catch((err) => { })
  }

  const [code, setCode] = useState("")
  const handleSearchClick = () => {
    setShow(true)
    var URL = baseurl + "adminsearchproduct";
    var Request = { 
      code: code,
      id:code,
      name:code
     };
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        setShow(false);
        setProductarray(res.data);

      })
      .catch();

  }

  return (
    <>
      <div className="adminproductlist">
        <Admintopbar
          title={"Product List"}
        />
        {/* <Adminmenubar /> */}
        <div className="adminproductlist_row">
          <div className="adminproductlist_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="adminproductlist_row_col2">
            <AdminTitle
              handleAddproductClick={handleAddproductClick}
              handleSearchClick={handleSearchClick}
              setCode={setCode}
              code={code}
              icon={
                <MdShoppingBasket
                  style={{
                    color: "#C3CAD9",
                    fontsize: 70,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                  }}
                />
              }
              label1="Products"
              // label2="Active"
              // label3="Draft"
              // label4="Assembly"
              label5="Add Product"
            />
            <Adminsubtitlelist
              tabMenus={tabMenus}
              setTabMenus={setTabMenus}
              onTabClicked={onTabClicked}
            />
            {/* <Adminsubtitle handleProducteditClick={handleProducteditClick} /> */}
            <Adminlistproduct
              handleProducteditClick={handleProducteditClick}
              show={show}
              productarray={productarray}
            />
          </div>
        </div>
      </div>
    </>
  );
}
