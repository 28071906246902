import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminsavetitle from "../Components/Adminsavetitle";
import Admineditsubtitle from "../Components/Admineditsubtitle";
import Adminproductmain from "../Components/Adminproductmain";
import Adminloader from "../Components/Adminloader";
import "./Admineditproductpage.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config.json";
import AdminUploadUrl from "../Components/AdminUploadUrl";
import Adminmenubar from "../Components/Adminmenubar";

export default function Admineditproductpage() {
  const navigate = useNavigate();
  const baseurl = config.url;
  const [product, setProduct] = useState("");
  const [prdname, setPrdname] = useState("");
  const [color, setColor] = useState("");
  const [prdstyle, setPrdstyle] = useState("");
  const [prdcode, setPrdcode] = useState("");
  const [prdtype, setPrdtype] = useState("");
  const [prdmetal, setPrdmetal] = useState("");
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [prddescription, setPrddescription] = useState("");
  const [prdcategory, setPrdcategory] = useState("");
  const [sequenceno, setSequenceno] = useState("");
  const [prdSubCategory, setPrdSubCategory] = useState("");
  const [featuredproduct, setFeaturedproduct] = useState("0");
  const [topcategories, setTopcategories] = useState("0");
  const [bestseller, setBestseller] = useState("0");
  const [latestproducts, setLatestProducts] = useState("0");
  const [updatedproductarray, setUpdatedproductarray] = useState([]);
  const [productarray, setProductarray] = useState([]);
  const [style, setStyle] = useState([]);
  const [weartype, setWearType] = useState([]);
  const [metal, setMetal] = useState([]);
  const [category, setcategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImage] = useState([]);
  const [selectedImages1, setSelectedImage1] = useState([]);
  const [currentupdate, setCurrentupdate] = useState([]);
  const [currentupdate1, setCurrentupdate1] = useState([]);
  const [file, setFile] = useState("");
  const [file1, setFile1] = useState("");
  const [editmedia, setEditMedia] = useState(true);
  const [editmain, setEditMain] = useState(true);
  const [GST, setGST] = useState("");
  const [stone, setStone] = useState("");
  const [mCharge, setMCharge] = useState("Percentage");
  const [sCharge, setSCharge] = useState("Percentage");
  const [priceCat, setPriceCat] = useState('916');
  const [sWeight,setSWeight]=useState("");
const [gWeight,setGWeight]=useState("");
const [nWeight,setNWeight]=useState("");
  // const [mediaprd,setMediaprd]=useState(true)
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage")
  }
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage")
  }
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage")
  }
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage")
  }
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage")
  }
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage")
  }
  // const handleProducteditClick=()=>{
  //     navigate("/Admineditproductpage")
  // }
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  }
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }
  var i = localStorage.getItem("prdid");

  const [checked, setChecked] = useState("");
  const [checked1, setChecked1] = useState("");
  const [checked2, setChecked2] = useState("");
  const [checked3, setChecked3] = useState("");
  const [checked4, setChecked4] = useState("");
  useEffect(() => {
    setShow(true);
    // var URL = baseurl + "adminsingleproductfetch";
    var URL = baseurl + "adminsingleproductfetch1";

    var Request = { id: i };
    console.log("product id" + JSON.stringify(Request));
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        setShow(false);
        // if(res.data>0){
        
        setPrdname(res.data.prd[0].prdname);
        setPrdcode(res.data.prd[0].prdcode);
        setColor(res.data.prd[0].color);
        setPrdcategory(res.data.prd[0].prdcategory);
        setPrdSubCategory(res.data.prd[0].prdsubcategory);
        setPrdstyle(res.data.prd[0].prdstyle);
        setPrdtype(res.data.prd[0].prdtype);
        setPrdmetal(res.data.prd[0].prdmetal);
        setPrice(res.data.prd[0].price);
        setWeight(res.data.prd[0].weight);
        setPrddescription(res.data.prd[0].prddescription);
        setSequenceno(res.data.prd[0].sequenceno);
        setFeaturedproduct(res.data.prd[0].featuredflag);
        setTopcategories(res.data.prd[0].topcategoryflag);
        setBestseller(res.data.prd[0].bestsellerflag);
        setLatestProducts(res.data.prd[0].latestprdflag);
        setFile1(res.data.prd[0].image);
        setStone(res.data.prd[0].stonecharge);
        // setSCharge(res.data.prd[0].stonechargetype)
        setGST(res.data.prd[0].GST);
        setNWeight(res.data.prd[0].netweight);
        setGWeight(res.data.prd[0].grossweigt);
        setSWeight(res.data.prd[0].stoneweight);
        setcategory(res.data.cat);
        // console.log("sub imag==>"+JSON.stringify(res.data.cat))
        setSubCategory(res.data.subcat);
        setWearType(res.data.type);
        setStyle(res.data.style);
        setMetal(res.data.metal);
        
        if (res.data.prd[0].featuredflag == 1) {
          setChecked1(true);
        } else {
          setChecked1(false)
        }

        if (res.data.prd[0].topcategoryflag == 1) {
          setChecked2(true);
        } else {
          setChecked2(false);
        }
        if (res.data.prd[0].bestsellerflag == 1) {
          setChecked3(true);
        } else {
          setChecked3(false);
        }

        if (res.data.prd[0].latestprdflag == 1) {
          setChecked4(true);
        } else {
          setChecked4(false);
        }
        setProductarray(res.data);
        if(res.data.prd[0].pricecategory!=''){
          setPriceCat(res.data.prd[0].pricecategory);
        }else{
          setPriceCat("916");
        }

        if(res.data.prd[0].pricetype!=''){
          setMCharge(res.data.prd[0].pricetype);
        }else{
          setMCharge("Percentage");
        }

        if(res.data.prd[0].stonechargetype!=''){
          setSCharge(res.data.prd[0].stonechargetype)
        }else{
          setSCharge("Percentage");
        }
        // console.log("Status true/false==>"+res.data[0].status);
        if (res.data.prd[0].status == 1) {
          setChecked(true);
        } else {
          setChecked(false);
        }
        var temp = [];
        for (var i = 0; i <= res.data.subimg.length; i++) {
          // temp.push(res.data[i].image)
          setSelectedImage(temp = [...temp, res.data.subimg[i].image]);
          
        }
        // setPriceCat(res.data[0].pricecategory==""?"916":res.data[0].pricecategory);

        

        // }
      })
      .catch();

    // var url1 = baseurl + "fetchsubimages";
    // var req1 = {
    //   id: i
    // };
    // var header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     // setShow(false);
    //     // alert(res.data)
    //     // setSelectedImage(res.data);
    //     var temp = [];
    //     for (var i = 0; i <= res.data.length; i++) {
    //       // temp.push(res.data[i].image)
    //       setSelectedImage(temp = [...temp, res.data[i].image]);
          
    //     }
    //     console.log(" temp==>" + selectedImages)
    //     // setSelectedImage(temp);
    //   })
    //   .catch();

    // var url1 = baseurl + "adminfetchcategory";
    // var req1 = {
    // };
    // var header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     // setShow(false);
    //     setcategory(res.data);
    //   })
    //   .catch();
    // var url2 = baseurl + "adminfetchsubcategory";
    // var req2 = {
    // };
    // var header2 = {};
    // axios
    //   .post(url2, req2, header2)
    //   .then((res) => {
    //     setShow(false);
    //     setSubCategory(res.data);
    //   })
    //   .catch();

    // var url3 = baseurl + "adminfetchweartype";
    // var req3 = {
    // };
    // var header3 = {};
    // axios
    //   .post(url3, req3, header3)
    //   .then((res) => {
    //     setWearType(res.data);
    //   })
    //   .catch();

    // var url4 = baseurl + "adminfetchstyle";
    // var req4 = {
    // };
    // var header4 = {};
    // axios
    //   .post(url4, req4, header4)
    //   .then((res) => {
    //     setStyle(res.data);
    //   })
    //   .catch();

    // var url5 = baseurl + "adminfetchmetal";
    // var req5 = {};
    // var header5 = {};
    // axios
    //   .post(url5, req5, header5)
    //   .then((res) => {
    //     setMetal(res.data);
    //   })
    //   .catch();
  }, []);

  const handleMChargeDropDown = (e) => {
    e.preventDefault();
    // if(mCharge==='amount'){
    setMCharge(e.target.value)
    // }else{
    //   setMCharge("percentage")
    // }
  }


  function handleProducteditClick() {
    setShow(true);
    var URL = baseurl + "admineditproduct";
    // var URL="";
    // if (prdname === '') {
    //   alert("Product name is empty");
    // } else if (prdcode === '') {
    //   alert("Product code is empty");
    // } else if (color === '') {
    //   alert("Product color is empty");
    // }else if (prdcategory === '') {
    //   alert("Product Category is empty");
    // } else if (prdSubCategory === '') {
    //   alert("Product Subcategory is empty");
    // } else if (prdstyle === '') {
    //   alert("Product Style is empty");
    // } else if (prdtype === '') {
    //   alert("Product Weartype is empty");
    // } else if (prdmetal === '') {
    //   alert("Product Metal is empty");
    // } else if (price === '') {
    //   alert("Product Making charge is empty");
    // } else if (weight === '') {
    //   alert("Product Weight is empty");
    // } else if (sequenceno === '') {
    //   alert("Product Sequence no is empty");
    // } else if (prddescription === '') {
    //   alert("Product Description is empty");
    // } else if (stone === '') {
    //   alert("Product Stone charge is empty");
    // } else if (GST === '') {
    //   alert("Product GST is empty");
    // } else 
    if (selectedImages.length === 0) {
      alert("Product Subimage is empty");
    } else if (file1 === '') {
      alert("Product Subimages is empty");
    } else {
      var Request = {
        prdname: prdname,
        prdcode: prdcode,
        color: color,
        prdcategory: prdcategory,
        prdsubcategory: prdSubCategory,
        prdstyle: prdstyle,
        prdtype: prdtype,
        prdmetal: prdmetal,
        price: price,
        weight: weight,
        prddescription: prddescription,
        seqno: sequenceno,
        latestflag: latestproducts,
        bestflag: bestseller,
        featuredflag: featuredproduct,
        categoryflag: topcategories,
        stonecharge: stone,
        GST: GST,
        stonetype: sCharge,
        costtype: mCharge,
        pricecat: priceCat,
        sweight: sWeight,
        gweight: gWeight,
        nweight: nWeight,
        img: selectedImages,
        img1: file1,
        prdid: i,
        pid: i,
        id: i,
      };

      var Headers = {};
      // if (prdname !== '' && prdcode !== '' && prdcategory !== '' && prdSubCategory !== '' && prdstyle !== '' && prdtype !== '' && prdmetal !== '' && price !== '' && weight !== '' && sequenceno !== '' && prddescription !== '' && stone !== '' && GST !== '' && selectedImages !== '' && file1 !== '') {

      axios
        .post(URL, Request, Headers)
        .then((res) => {
          setShow(false);
          if (res.data === 1) {
            alert("Sucessfully Saved");
            setUpdatedproductarray(res.data);
            navigate("/Adminproductlistpage");
          } else {
            alert("Save Failed");
          }
        })
        .catch((err) => {
          alert("Save Failed");
        });
    }
    // var URL1 = baseurl + "adminupdateinserimages";
    // var Request1 = {
    //   img: selectedImages,
    //   prdid: i,
    //   pid: i,
    // };
    // console.log(Request);
    // var Headers1 = {};
    // if(selectedImages === ''){
    //   alert("Product Subimage is empty");
    // }else{
    // axios
    //   .post(URL1, Request1, Headers1)
    //   .then((res) => {
    //     console.log("Updated image" + JSON.stringify(res.data));
    //     setCurrentupdate(res.data);
    //   })
    //   .catch();
    // }

    // var url = baseurl + "adminupdateinsertmainimage";
    // var req = {
    //   img: file1,
    //   prdid: i,
    //   pid: i,
    // };
    // console.log(Request);
    // var header = {};
    // if(file1 === ''){
    //   alert("Product Main images is empty");
    // }else{
    // axios
    //   .post(url, req, header)
    //   .then((res) => {
    //     setShow(false);
    //     console.log("Updated main image" + JSON.stringify(res.data));
    //     setCurrentupdate1(res.data);
    //     // navigate("/Adminproductlistpage");
    //   })
    //   .catch();
    // }

  }


  //upload image

  const handleUploadChange = (e) => {
    if (e.target.files.length != 0 && e.target.files.length <= 1) {
      console.log("image name==>", e.target.files);
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      // console.log("Array==>",selectedFilesArray);
      // const imageArray = selectedFilesArray.map((file) => {
      //   return URL.createObjectURL(file);
      // });
      // setSelectedImage1((previousImages) => previousImages.concat(imageArray));
      // // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
      // function toDataURL(url, callback) {
      //   var xhr = new XMLHttpRequest();
      //   xhr.onload = function () {
      //     var reader = new FileReader();
      //     reader.onloadend = function () {
      //       callback(reader.result);
      //     };
      //     reader.readAsDataURL(xhr.response);
      //   };
      //   xhr.open("GET", url);
      //   xhr.responseType = "blob";
      //   xhr.send();
      // }

      // toDataURL(selectedImages1, function (dataUrl) {
      //   console.log("RESULT:", dataUrl);
      //   setFile1(dataUrl);
      // });
      let reader = new FileReader();
      const imageArray = selectedFilesArray.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log("image:" + reader.result);
          console.log("img size==>" + reader.result.length)
          const imglength = reader.result.length / 1000;
          if (imglength < 300) {
          setFile1(reader.result)
        } else {
          alert("Image size limit is upto 300kb")
        }
        };
      });

    }
  };

  const handleImagesUploadChange = (e) => {
    if (selectedImages.length < 3) {
      console.log("image name==>", e.target.files);
      // const c= e.target.files.length;
      // console.log("main img length==>",c);
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      // console.log("Array==>",selectedFilesArray);
      // const imageArray = selectedFilesArray.map((file) => {
      //   return URL.createObjectURL(file);
      // });
      // setSelectedImage((previousImages) => previousImages.concat(imageArray));
      // // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
      // const c = selectedImages.length + 1;
      // console.log("sub img length==>", selectedImages);
      // localStorage.setItem("sublength", c);
      // function toDataURL(url, callback) {
      //   var xhr = new XMLHttpRequest();
      //   xhr.onload = function () {
      //     var reader = new FileReader();
      //     reader.onloadend = function () {
      //       callback(reader.result);
      //     };
      //     reader.readAsDataURL(xhr.response);
      //   };
      //   xhr.open("GET", url);
      //   xhr.responseType = "blob";
      //   xhr.send();
      // }

      // toDataURL(selectedImages, function (dataUrl) {
      //   console.log("RESULT:", dataUrl);
      //   setFile(dataUrl);
      // });

      let reader = new FileReader();
      const imageArray = selectedFilesArray.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log("image:" + reader.result);
          // setFile1(reader.result)
          console.log("img size==>" + reader.result.length)
          const imglength = reader.result.length / 1000;
          if (imglength < 300) {
          setSelectedImage((previousImages) => previousImages.concat(reader.result));
        } else {
          alert("Image size limit is upto 300kb")
        }
        };
      });
    }else{
      alert("Maximum 3 subimages")
    }
  };
  // function handleProducteditcompClick() {
  //   // navigate('/Adminproductmain')
  // }

  // function handlemediacompClick(menu, indx) {
  //   // navigate('/AdminUploadUrl');
  //   if (menu.id == 1) {
  //     setEditMain(editmain ? true : false);
  //   } else {
  //     setEditMedia(editmedia ? true : false);
  //   }
  // }

  const handleStatusChange = (e) => {
setShow(true)
    setChecked(current => !current);
    console.log("checked==>" + checked);
    if (checked !== true) {
      const url = baseurl + "adminupdateproductstatus";
      const req = { status: 1, id: i };
      const header = {}
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          // console.log("status==>" + 1);
        })
        .catch((err) => { })

    } else {
      const url = baseurl + "adminupdateproductstatus";
      const req = { status: 0, id: i };
      const header = {}
      axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
          // console.log("status==>" + 0);
        })
        .catch((err) => { })

    }
  };

  const [tabMenus, setTabMenus] = useState([{
    id: 1,
    name: "Main",
    selected: true
  }, {
    id: 2,
    name: "Media",
    selected: false
  }]);

  // function notify() {
  //   // Get the snackbar DIV
  //   var x = document.getElementById("snackbar");
  //   // Add the "show" class to DIV
  //   x.className = "show";
  //   // After 3 seconds, remove the show class from DIV
  //   setTimeout(function () {
  //     x.className = x.className.replace("show", "");
  //   }, 3000);
  //   // window.location.reload(false);
  // }


  return (
    <>
      <div className="adminaddproduct">
        <Admintopbar
          title={"Edit Product"}

        />
        {/* <Adminmenubar /> */}
        <div className="admineditproduct_row">
          <div className="admineditproduct_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              //  handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="admineditproductpage_loader">
          <Adminloader show={show} />
          </div>
          <div className="admineditproduct_row_col2">
            <Adminsavetitle content={"Save product"}
              handleProducteditClick={handleProducteditClick}
              handleStatusChange={handleStatusChange}
              checked={checked}
              setChecked={setChecked}
            // notify={notify}
            // label={label}
            />
            <div>
              <Admineditsubtitle tabMenus={tabMenus} setTabMenus={setTabMenus} />
              {tabMenus[0].selected ? 
              <Adminproductmain
                // prop
                editmain={editmain}
                setEditMain={setEditMain}
                featuredproduct={featuredproduct}
                setFeaturedproduct={setFeaturedproduct}
                latestproducts={latestproducts}
                setLatestProducts={setLatestProducts}
                topcategories={topcategories}
                setTopcategories={setTopcategories}
                bestseller={bestseller}
                setBestseller={setBestseller}
                prdname={prdname}
                setPrdname={setPrdname}
                product={product}
                setProduct={setProduct}
                color={color}
                setColor={setColor}
                prdstyle={prdstyle}
                setPrdstyle={setPrdstyle}
                prdcode={prdcode}
                setPrdcode={setPrdcode}
                prdtype={prdtype}
                setPrdtype={setPrdtype}
                prdmetal={prdmetal}
                setPrdmetal={setPrdmetal} price={price} setPrice={setPrice}
                weight={weight}
                setWeight={setWeight}
                prddescription={prddescription}
                setPrddescription={setPrddescription}
                prdcategory={prdcategory}
                setPrdcategory={setPrdcategory}
                prdSubCategory={prdSubCategory} setPrdSubCategory={setPrdSubCategory}
                sequenceno={sequenceno}
                setSequenceno={setSequenceno}
                setUpdatedproductarray={setUpdatedproductarray}
                style={style}
                setStyle={setStyle}
                weartype={weartype}
                metal={metal}
                setMetal={setMetal}
                category={category}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                setcategory={setcategory}
                setWearType={setWearType}
                productarray={productarray}
                setProductarray={setProductarray}
                checked1={checked1}
                setChecked1={setChecked1}
                checked2={checked2}
                setChecked2={setChecked2}
                checked3={checked3}
                setChecked3={setChecked3}
                checked4={checked4}
                setChecked4={setChecked4}
                show={show}
                GST={GST}
                setGST={setGST}
                stone={stone}
                setStone={setStone}
                mCharge={mCharge}
                setMCharge={setMCharge}
                sCharge={sCharge}
                setSCharge={setSCharge}
                handleMChargeDropDown={handleMChargeDropDown}
                priceCat={priceCat}
                setPriceCat={setPriceCat}
                updatedproductarray={updatedproductarray}
                sWeight={sWeight}
                setSWeight={setSWeight}
                gWeight={gWeight}
                setGWeight={setGWeight}
                nWeight={nWeight}
                setNWeight={setNWeight}
              // handleProducteditcompClick={handleProducteditcompClick}
              /> : <AdminUploadUrl
                // editmedia={editmedia} setEditMedia={setEditMedia}
                // handleProducteditClick={handleProducteditClick}
                handleImagesUploadChange={handleImagesUploadChange}
                handleUploadChange={handleUploadChange}
                selectedImages={selectedImages}
                setSelectedImage={setSelectedImage}
                selectedImages1={selectedImages1}
                setSelectedImage1={setSelectedImage1}
                currentupdate={currentupdate}
                setCurrentupdate={setCurrentupdate}
                currentupdate1={currentupdate1}
                setCurrentupdate1={setCurrentupdate1}
                file1={file1}
                setFile1={setFile1}
              />}
            </div>

            <div>
              {/* <Admineditsubtitle selectedTabId={2} handlemediacompClick={handlemediacompClick} /> */}


            </div>

          </div>
        </div>
      </div>
    </>
  );
}
