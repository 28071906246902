import './AdminTitle.css';
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import Header_searchlens from "./Images/Header_searchlens.png";

function AdminTitle({ handleSearchClick,setCode,code,handleAddproductClick, label1, label2, label3, label4, label5, icon }) {
    return (
        <>
            <div className="AdminTitle_Titlemenu">
                <div className="AdminTitle_Titlemenu_col1">
                    <div className="AdminTitle_Titmenu_col1_col1">
                        <icon>{icon}</icon>
                    </div>
                    <div className="AdminTitle_Titmenu_col1_col2">
                        <label>{label1}</label>
                    </div>
                </div>
                <div className="AdminTitle_Titlemenu_col2">
                    <div className="AdminTitle_Titmenu_col2_co1">
                        <label >{label2}</label>
                    </div>
                    <div className="AdminTitle_Titmenu_col2_co1">
                        <label >{label3}</label>
                    </div>
                    <div className="AdminTitle_Titmenu_col2_co1">
                        <label>{label4}</label>
                    </div>
                </div>
                <div className="Header_r2_col3">
                    <input type="text" value={code} onChange={(e)=>setCode(e.target.value)} />
                    <div className="Header_r2_col3 input">
                        <img src={Header_searchlens} onClick={(e)=>handleSearchClick(e)} />
                    </div>
                </div>
                <div className="AdminTitle_Titlemenu_col3">
                    <div className="AdminTitle_Titmenu_col3_iconbutton">
                        <MdAddCircle style={{ color: '#C499FF', fontSize: '20px' }} />
                        <button onClick={(e) => { handleAddproductClick() }} className="AdminTitle_Titlemenu_col3_button">{label5}</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminTitle;