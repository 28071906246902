import React, { useEffect, useState } from "react";
import "./Adminaddproductmain.css";
import "./Admineditsubtitle.css";
import "./Adminsavetitle.css";
import { useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { IoMdLink } from "react-icons/io";
import { BsTypeBold } from "react-icons/bs";
import { RiFontSize2 } from "react-icons/ri";
import { MdFormatItalic } from "react-icons/md";
import { AiOutlineUnderline } from "react-icons/ai";
import { AiOutlineFontColors } from "react-icons/ai";
import { AiOutlineAlignLeft } from "react-icons/ai";
import { MdFormatListNumbered } from "react-icons/md";
import { MdFormatListBulleted } from "react-icons/md";
import { MdStrikethroughS } from "react-icons/md";
import { MdOutlineFormatClear } from "react-icons/md";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import axios from "axios";
import { Fragment } from "react";
import Adminaddsubtitle from "./Adminaddsubtitle";
import config from "../config.json";
// import moment from "moment";

export default function Adminproductmain({ sWeight,setSWeight,gWeight,setGWeight,nWeight,setNWeight,priceCat, setPriceCat, GST, setGST, stone, setStone, show, mCharge, setMCharge, sCharge, setSCharge, style, setStyle, weartype, metal, setMetal, category, subCategory, setSubCategory, setcategory, setWearType, productarray, setProductarray, setFeaturedproduct, setBestseller, setLatestProducts, setTopcategories, featuredproduct, latestproducts, topcategories, bestseller, prdname, setPrdname, product, setProduct, color, setColor, prdstyle, setPrdstyle, prdcode, setPrdcode, prdtype, setPrdtype, prdmetal, setPrdmetal, price, setPrice, weight, setWeight, prddescription, setPrddescription, prdcategory, setPrdcategory, prdSubCategory, setPrdSubCategory, sequenceno, setSequenceno, setUpdatedproductarray, handleProductAddClick }) {
  const baseurl = config.url;
  const [checked1, setChecked1] = useState("");
  const [checked2, setChecked2] = useState("");
  const [checked3, setChecked3] = useState("");
  const [checked4, setChecked4] = useState("");
  const [active, setActive] = useState("admineditsubtitle_Titmenu_col2_co1");
  const [isSelect, setIsSelect] = useState(
    "admineditsubtitle_Titmenu_col2_co1"
  );
  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";

    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }

  const navigate = useNavigate();

  const handleClick = (event) => {
    setActive("main_isselected");
    setIsSelect("main_isselected");
    localStorage.getItem("active", active);
    localStorage.getItem("selected", isSelect);
  };

  function handlefeaturedproductClick(e, item) {
    setChecked1(current => !current);
    if (checked1 !== true) {
      setFeaturedproduct("1");
      console.log("featured==>" + 1)
    } else {
      setFeaturedproduct("0");
      console.log("featured==>" + 0)
    }

  }
  function handletopcategoriesClick(e, item) {
    setChecked2(current => !current);
    if (checked2 !== true) {
      setTopcategories("1");
    } else {
      setTopcategories("0");
    }

  }

  function handleBestsellerClick(e, item) {
    setChecked3(current => !current);
    if (checked3 !== true) {
      setBestseller("1");
    } else {
      setBestseller("0");
    }
  }
  const handleLatestproductClick = (e, item) => {
    setChecked4(current => !current);
    if (checked4 !== true) {
      setLatestProducts("1");
    } else {
      setLatestProducts("0");
    }
  }

  return (
    <>
      <div className="admineditproduct">

        <div className="admineditproduct_row3">
          {/* {productarray.map((item, index) => { */}
          {/* return ( */}
          <>
            <div className="adminproductmain">
              <div className="adminproductmain_col1">
                <div className="adminproductmain_col1_row1">
                  <label>Main parameters</label>
                  <BsThreeDots
                    style={{ color: "#C3CAD9", fontSize: "18px" }}
                  />
                </div>
                <div className="adminproductmain_col1_row2_outer">
                  <div className="adminproductmain_col1_row2">
                    <div className="adminproductmain_col1_row2_col1">
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>Product</label>
                        <input
                          placeholder="Product"
                          value={prdname}
                          onChange={(e) => setPrdname(e.target.value)}
                        ></input>
                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>Color</label>
                        <input
                          placeholder="Rose Gold"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        ></input>
                      </div>
                      {/* <div className="adminproductmain_col1_row2_col1_row">
                            <label>Style</label>
                            <div className="adminproductmain_col1_row2_row1_col_input">
                              <input
                                type={"text"}
                                value={prdstyle}
                                placeholder="casual"
                                onChange={(e) => setPrdstyle(e.target.value)}
                              ></input>
                            </div>
                          </div>
                          <div className="adminproductmain_col1_row2_col1_row">
                            <label>Metal</label>
                            <div className="adminproductmain_col1_row2_col1_row_input">
                              <input
                                type="text"
                                placeholder="Diamond"
                                value={prdmetal}
                                onChange={(e) => setPrdmetal(e.target.value)}
                              />
                            </div>
                          </div> */}
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>
                          Category
                        </label>
                        <div className="adminproductmain_col1_row2_col1_row_select">
                          <select onChange={(e) => setPrdcategory(e.target.value)}
                            className="productmain_select"
                            value={prdcategory}>
                            <option placeholder="select"></option>
                            {category.map((itm, indx) => {
                              return (
                                <option value={itm.id} className={itm.id}>{itm.categoryname}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>
                          Shop by style
                        </label>
                        <div className="adminproductmain_col1_row2_col1_row_select">
                          <select onChange={(e) => setPrdstyle(e.target.value)}
                            className="productmain_select"
                            placeholder="select"
                            value={prdstyle}>
                            <option placeholder="select"></option>
                            {style.map((itm, indx) => {
                              return (
                                <option value={itm.id}>{itm.stylenames}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>
                          Shop by metal
                        </label>
                        <div className="adminproductmain_col1_row2_col1_row_select">
                          <select onChange={(e) => setPrdmetal(e.target.value)}
                            className="productmain_select"
                            value={prdmetal}>
                            <option placeholder="sekect"></option>
                            {metal.map((itm, indx) => {
                              return (
                                <option value={itm.id}>{itm.metalname}</option>
                              )
                            })}
                          </select>
                        </div>

                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>Stone Weight(g)</label>
                        <input
                          placeholder="Stone Weight"
                          value={sWeight}
                          onChange={(e) => setSWeight(e.target.value)}
                        ></input>
                      </div>
                    </div>
                    <div className="adminproductmain_col1_row2_col2">
                      <div className="adminproductmain_col1_row2_col2_row">
                        <label>Code</label>
                        <input
                          placeholder="R152125"
                          value={prdcode}
                          onChange={(e) => setPrdcode(e.target.value)}
                        ></input>
                      </div>
                      {/* <div className="adminproductmain_col1_row2_col2_row">
                            <label>Category</label>
                            <input
                              placeholder="Ring"
                              value={prdcategory}
                              onChange={(e) => setPrdcategory(e.target.value)}
                            ></input>
                          </div>
                          <div className="adminproductmain_col1_row2_col2_row">
                            <label>Product Type</label>
                            <div className="adminproductmain_col1_row2_row1_col_input">
                              <input
                                type={"text"}
                                placeholder="Daily Wear"
                                value={prdtype}
                                onChange={(e) => setPrdtype(e.target.value)}
                              ></input>
                            </div>
                          </div> */}
                      <div className="adminproductmain_col1_row2_col2_row">
                        <label>Sequence Number</label>
                        <div className="adminproductmain_col1_row2_row1_col_input">
                          <input type={"text"}
                            value={sequenceno}
                            placeholder="sequence number"
                            onChange={(e) => setSequenceno(e.target.value)}
                          >
                          </input>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>
                          Sub Category
                        </label>
                        <div className="adminproductmain_col1_row2_col1_row_select">
                          <select onChange={(e) => setPrdSubCategory(e.target.value)}
                            value={prdSubCategory}
                            className="productmain_select"
                          >
                            <option placeholder="select"></option>
                            {subCategory.map((itm, indx) => {
                              return (
                                <option value={itm.id}>{itm.subcategoryname}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col1_row">
                        <label>
                          Shop by type
                        </label>
                        <div className="adminproductmain_col1_row2_col1_row_select">
                          <select onChange={(e) => setPrdtype(e.target.value)}
                            className="productmain_select"
                            value={prdtype}>
                            <option placeholder="select"></option>
                            {weartype.map((itm, indx) => {
                              return (
                                <option value={itm.id}>{itm.weartypenames}</option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col2_row">
                        <label>Gross Weight(g)</label>
                        <div className="adminproductmain_col1_row2_row1_col_input">
                          <input type={"text"}
                            value={gWeight}
                            placeholder="Gross Weigt"
                            onChange={(e) => setGWeight(e.target.value)}
                          >
                          </input>
                        </div>
                      </div>
                      <div className="adminproductmain_col1_row2_col2_row">
                        <label>Net Weight(g)</label>
                        <div className="adminproductmain_col1_row2_row1_col_input">
                          <input type={"text"}
                            value={nWeight}
                            placeholder="Net Weight"
                            onChange={(e) => setNWeight(e.target.value)}
                          >
                          </input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="adminproductmain_col1_row3">
                    <div className="adminproductmain_col1_row3_col">

                      <div className="adminproductmain_col1_row2_col1_row1">
                        <div className="adminproductmain_col1_row2_col1_row_cont">
                          <div className="adminproductmain_col1_row2_col1_row_cont_checkbox">
                            <input type={"checkbox"}
                              checked={checked1}
                              onChange={(e) => {
                                handlefeaturedproductClick()
                              }}>
                            </input>
                          </div>
                          <div className="adminproductmain_col1_row2_col1_row_cont_label">
                            <label>Featured Product</label>
                          </div>
                        </div>
                        <div className="adminproductmain_col1_row2_col1_row_cont">
                          <div className="adminproductmain_col1_row2_col1_row_cont_checkbox">
                            <input type={"checkbox"}
                              checked={checked2}
                              onChange={(e) => {
                                handletopcategoriesClick()
                              }}>
                            </input>
                          </div>
                          <div className="adminproductmain_col1_row2_col1_row_cont_label">
                            <label>Top Categories</label>
                          </div>
                        </div>
                        <div className="adminproductmain_col1_row2_col1_row_cont">

                          <div className="adminproductmain_col1_row2_col1_row_cont_checkbox">
                            <input type={"checkbox"}
                              checked={checked3}
                              onChange={(e) => {
                                handleBestsellerClick()
                              }}>
                            </input>
                          </div>
                          <div className="adminproductmain_col1_row2_col1_row_cont_label">
                            <label>Best Seller</label>
                          </div>
                        </div>
                        <div className="adminproductmain_col1_row2_col1_row_cont">

                          <div className="adminproductmain_col1_row2_col1_row_cont_checkbox">
                            <input type={"checkbox"}
                              checked={checked4}
                              // style={{zIndex:'100'}}
                              // checked={latestproducts=="1"?true:false}
                              onClick={(e) => {
                                handleLatestproductClick()
                              }}
                            >
                            </input>
                          </div>
                          <div className="adminproductmain_col1_row2_col1_row_cont_label">
                            <label>Latest Products</label>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="adminproductmain_col2">
                <div className="adminproductmain_col2_row1">
                  <div className="adminproductmain_col2_row1_outer">
                    <div className="adminproductmain_col2_row1_outer_row1">
                      <label>Prices </label>
                      <BsThreeDots
                        style={{ color: "#C3CAD9", fontSize: "18px" }}
                      />
                    </div>
                    <div className="adminproductmain_col2_row1_outer_row2_col1">
                      <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                        <label> Price Category</label>
                        <div className="adminproductmain_col2_row1_outer_row2_col1_row_select2">
                          <select
                            value={priceCat}
                            onChange={(e) => setPriceCat(e.target.value)}
                          >
                            <option>916</option>
                            <option>18 carat</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="adminproductmain_col2_row1_outer_row2">
                      <div className="adminproductmain_col2_row1_outer_row2_col1">
                        <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                          <label>Cost (%)</label>
                          <input
                            type="text"
                            value={price}
                            placeholder="432"
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                        <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                          <label>Making Charge Type</label>
                          <div className="adminproductmain_col2_row1_outer_row2_col1_row_select">
                            <select
                              value={mCharge}
                              onChange={(e) => setMCharge(e.target.value)}
                            >
                              <option>Percentage</option>
                              <option>Amount</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="adminproductmain_col2_row1_outer_row2_col1">
                      <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                        <label>Stone Charge</label>
                        <input
                          type="text"
                          value={stone}
                          placeholder="Stone Charge"
                          onChange={(e) => setStone(e.target.value)}
                        />
                      </div>
                      <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                        <label>Stone Charge Type</label>
                        <div className="adminproductmain_col2_row1_outer_row2_col1_row_select">
                          <select
                            value={sCharge}
                            onChange={(e) => setSCharge(e.target.value)}
                          >
                            <option >Percentage</option>
                            <option>Amount</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/***************/}
                    <div className="adminproductmain_col2_row1_outer_row2_col1">
                      <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                        <label>Weight (gm)</label>
                        {/* <div className="adminproductmain_col2_row1_outer_row2_col1_row_select"> */}
                        <input
                          type="text"
                          value={weight}
                          placeholder="33.767"
                          onChange={(e) => setWeight(e.target.value)}
                        />
                        {/* </div> */}
                      </div>
                      <div className="adminproductmain_col2_row1_outer_row2_col1_row">
                        <label>GST</label>
                        <div className="adminproductmain_col2_row1_outer_row2_col1_row_select">
                          <input
                            type="text"
                            value={GST}
                            placeholder="GST"
                            onChange={(e) => setGST(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/***************/}
                  </div>
                </div>
                <div className="adminproductmain_col2_row2">
                  <div className="adminproductmain_col2_row2_inner">
                    <div className="adminproductmain_col2_row2_inner_row1">
                      <label>Description</label>
                      <BsThreeDots
                        style={{ color: "#C3CAD9", fontSize: "18px" }}
                      />
                    </div>
                    <div className="adminproductmain_col2_row2_inner_row2">
                      <div className="adminproductmain_col2_row2_inner_row2_row1">
                        <RiFontSize2
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <BsTypeBold
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <MdFormatItalic
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <AiOutlineUnderline
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <AiOutlineFontColors
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <AiOutlineAlignLeft
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <MdFormatListNumbered
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <MdFormatListBulleted
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <MdStrikethroughS
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                        <MdOutlineFormatClear
                          style={{ color: "#C3CAD9", fontSize: "20px" }}
                        />
                      </div>
                    </div>
                    <div className="adminproductmain_col2_row2_inner_row3">
                      <textarea
                        type="text"
                        value={prddescription}
                        placeholder="Free Shipping In India | Hallmarked jewellery available for sale."
                        onChange={(e) => setPrddescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* );
          })} */}
        </div>
      </div>
    </>
  );
}
