import "../style.css";
import AdminImages from "../Components/AdminImages";
import AdminUploadFile from "../Components/AdminUploadFile";
import AdminUploadUrl from "../Components/AdminUploadUrl";
import AdminVideo from "../Components/AdminVideo";
import Admineditsubtitle from "../Components/Admineditsubtitle";
import Adminsavetitle from "../Components/Adminsavetitle";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import { useNavigate } from "react-router-dom";
import ".//Adminproductmediapage.css";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminproductmediapage() {
  const navigate = useNavigate();
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage");
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }

  return (
    <>
      <div className="adminproductmediapage">
        <div className="adminproductmediapage_row1">
          <Admintopbar
            title={"Update Image"}
          />
          {/* <Adminmenubar /> */}
        </div>

        <div className="adminproductmediapage_row2">
          <div className="adminproductmediapage_row2_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          {/* <div className="adminproductmediapage_row2_col2">
                        <div className="adminproductmediapage_row2_col2_row1">
                            <Adminsavetitle content={"Save Product"}/>
                            <Admineditsubtitle />
                        </div>
                        <div className="adminproductmediapage_row2_col2_inner"> */}
          <div className="adminproductmediapage_row2_col2_inner_row2">
            {/* <AdminUploadFile /> */}
            {/* <div className="adminproductmediapage_row2_col2_inner_row2_inner"> */}
            <AdminUploadUrl />
          </div>
          {/* </div> */}
          {/* <div className="adminproductmediapage_row2_col2_inner_row3">
                                <AdminImages />
                                <AdminVideo />
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
