import "./Admineditsubtitle.css";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Fragment } from "react";

export default function Admineditsubtitle({tabMenus, setTabMenus}) {
  //   const[file,setFile]=useState();
  // function fileSelectedHandler(event){
  //    console.log(event.target.files[0])
  //    setFile(URL.createObjectURL(event.target.files[0]))
  // }

  const navigate = useNavigate();
  // const { selectedTabId, tabmenu } = props;
  const [counter, setCounter] = useState(0);

  
    const tabClicked = (menu,idx) => {
    //alert(JSON.stringify(menu))

    let temp=[...tabMenus]
    for (const iterator of temp) { 
      iterator.selected=false;
    }
    temp[idx].selected=true;

    setTabMenus(temp)
  }
  return (
    <>
      <div className="admineditsubtitle">
        <div className="admineditsubtitle_Titlemenu">
          <Fragment>
            <div className="admineditsubtitle_Titlemenu_col2">
              {tabMenus.map((menu,idx) => (
                <div key={menu.name} className="admineditsubtitle_Titmenu_col2_co1">
                  {/* <label onClick={() => onTabClicked(menu, tabMenus, counter)} className={menu.selected ? "selected" : ""}>{menu.name}</label></div>))} */}
                  <label onClick={(e) =>{tabClicked(menu,idx);}} className={menu.selected ? "selected" : ""}>{menu.name}</label></div>))}
            </div>
          </Fragment>
        </div>
      </div>
    </>
  );
}