import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminordertitle from "../Components/Adminordertitle";
import Adminsubtitle from "../Components/Adminsubtitle";
import Adminlistuser from "../Components/Adminlistuser"
import { HiUsers } from "react-icons/hi";
import "./Adminuserlistpage.css";
import { useNavigate } from "react-router-dom";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminuserlistpage() {
  const navigate = useNavigate();
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage")
  }
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage")
  }
  const handleProfileeditClick = (e, item) => {
    // e.preventDefault();
    localStorage.setItem("Id", item.id);
    console.log("set id>>>" + item.id);
    navigate("/Adminprofileeditpage")
  }
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage")
  }
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage")
  }
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage")
  }
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage")
  }
  const handleAddproductClick = () => {
    navigate("/Adminaddproductpage")
  }
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }
  return (
    <>
      <div className="adminuserlist">
        <Admintopbar
          title={"User List"}
        />
        {/* <Adminmenubar /> */}
        <div className="adminuserlist_row">
          <div className="adminuserlist_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="adminuserlist_row_col2">
            <Adminordertitle icon={<HiUsers style={{ color: '#C3CAD9', fontsize: 70, width: 20, height: 20, textAlign: 'center' }} />}
              label1="Users"
              // label2="Active" label3="Archive" 
              label5="Add user"
            />
            {/* <Adminsubtitle handleProfileeditClick={handleProfileeditClick} /> */}
            <Adminlistuser handleProfileeditClick={handleProfileeditClick} />
          </div>
        </div>
      </div>
    </>
  );
}
