import "./Admintasksheet.css";
import { BsCheckLg } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config.json";

export default function Admintasksheet() {
    const baseurl = config.url;
    const [array, setArray] = useState([]);
    useEffect(() => {
        // alert("hello")
        // setShow(true);
        var URL = baseurl + "statuscount";
        var Request = {};
        var Headers = {};
        axios.post(URL, Request, Headers)
            .then((res) => {
                setArray(res.data[0]);
                console.log("array==>" + JSON.stringify(res.data[0]))
                // setOrderarray(res.data);
                // setShow(false);
            })
            .catch()
    }, [])

    const [tabMenus, setTabMenus] = useState([
        {
            id: 1,
            name: "All",
            selected: true,
        },
        {
            id: 2,
            name: "Pending",
            selected: false,
        },
        {
            id: 3,
            name: "Processing",
            selected: false,
        },
        {
            id: 4,
            name: "Conformed",
            selected: false,
        },
        {
            id: 5,
            name: "Delivered",
            selected: false,
        },
        {
            id: 6,
            name: "Rejected",
            selected: false,
        },
    ]);

    return (
        <>
            <div className="admintasksheet">
                <div className="admintasksheet_row1">
                    <div className="admintasksheet_row1_col1">
                        <SingleAdmintasksheet
                            label1={`${array.pending} ${tabMenus[1].name}`}
                            label2={"In current status"}
                            tabMenus={tabMenus}
                            menu={tabMenus[1].name}
                            cls="singleAdmintasksheet_col1_circle"
                            icon="singleAdmintasksheet_col1_icon1"
                        />
                    </div>
                    <div className="admintasksheet_row1_col2">
                        <SingleAdmintasksheet
                            label1={`${array.processed} ${tabMenus[2].name}`}
                            label2={"In current status"}
                            tabMenus={tabMenus}
                            menu={tabMenus[2].name}
                            cls="singleAdmintasksheet_col1_circle"
                            icon="singleAdmintasksheet_col1_icon2"
                        />
                    </div>
                    <div className="admintasksheet_row1_col3">
                        <SingleAdmintasksheet
                            label1={`${array.conformed} ${tabMenus[3].name}`}
                            label2={"In current status"}
                            tabMenus={tabMenus}
                            menu={tabMenus[3].name}
                            cls="singleAdmintasksheet_col1_circle"
                            icon="singleAdmintasksheet_col1_icon3"
                        />
                    </div>
                    <div className="admintasksheet_row1_col4">
                        <SingleAdmintasksheet
                            label1={`${array.delivered} ${tabMenus[4].name}`}
                            label2={"In current status"}
                            tabMenus={tabMenus}
                            menu={tabMenus[4].name}
                            cls="singleAdmintasksheet_col1_circle"
                            icon="singleAdmintasksheet_col1_icon4"
                        />
                    </div>
                </div>
                <div className="admintasksheet_row1">
                </div>
            </div>
        </>
    )
}

function SingleAdmintasksheet({ label1, label2, tabMenus, menu,cls,icon }) {
    const navigate = useNavigate();

    const handleFunctionClick = (e, tabMenus) => {
        for (var i = 0; i < tabMenus.length; i++) {
            if (tabMenus[i].name == menu) {
                localStorage.setItem("tabindx", i);
                localStorage.setItem("tabmenu", menu);
                navigate("/adminorderlistpage")
            }
        }
    }

    return (
        <>
            <div className="singleAdmintasksheet" onClick={(e) => handleFunctionClick(e, tabMenus, menu)}>
                <div className="singleAdmintasksheet_col1">
                    <div className={cls}>
                        <BsCheckLg className={icon}/>
                    </div>
                </div>
                <div className="singleAdmintasksheet_col2">
                    <div className="singleAdmintasksheet_col2_row1">{label1}</div>
                    <div className="singleAdmintasksheet_col2_row2">{label2}</div>
                </div>
            </div>
        </>
    )
}