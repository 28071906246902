import React from "react";
import "./Admineditcategory.css";
import "./Adminaddsavetitle.css";
import { FaClipboardList } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import config from "../config.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Admincategorytitle from "./Admincategorytitle";
import Adminloader from "./Adminloader";

export default function Admineditcategory() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [array, setArray] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [category, setCategory] = useState("");
  const [sequenceno,setSequenceno]=useState("");
  const [checked,setChecked]=useState("");
  const [show, setShow] = useState(false);
  const id=    localStorage.getItem("categoryid")
  useEffect(() => {
    setShow(true);
    const url = baseurl + "adminfetchsinglecategory";
    const req = {id:id};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setArray(res.data);
        setCategory(res.data[0].categoryname);
        setSequenceno(res.data[0].sequenceno);
        if(res.data[0].status==1){
          setChecked(true);
        }else{
          setChecked(false);
        }
        // console.log("a==>"+res.data[0].categoryname)
      })
      .catch();
  },[]);

  const handleEditCategoryClick = () => {
    setShow(true);
    const url = baseurl + "adminupdatecategory";
    const req = { id: id, catname:category,seqno:sequenceno };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setCatArray(res.data);
        navigate("/Admincategorylistpage")
      })
      .catch();

  }

  const handleStatusChange = (e) => {
    setShow(true);
    setChecked(current => !current);
    console.log("checked==>" + checked);
    const url = baseurl + "adminupdatecategorystatus";
    if (checked !== true) {
        var req = { status: 1,id:id };
    } else {
        var req = { status: 0,id:id };
    }
    const header = {}
    axios
        .post(url, req, header)
        .then((res) => {
          setShow(false);
            // console.log("status==>" + 1);
        })
        .catch((err) => { })
  }

  return (
    <>
      <div className="admineditcategory">
        <div className="admineditcategory_inner">
          <Admincategorytitle
              label1="Edit Category"
              icon={
                <FaClipboardList
                  style={{
                    color: "#C3CAD9",
                    fontsize: 70,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                  }}
                />
              }
              // label2="Active"
              // label3="Draft"
              // label4="Assembly"
              label5="Edit Category"
              handleAddproductClick={handleEditCategoryClick}
              handleStatusChange={handleStatusChange}
              checked={checked}
            />
            <div className="admineditcategorylist_loader">
              <Adminloader show={show} />
            </div>
          <div className="admineditcategory_row2">
            <div className="admineditcategory_row2_row">
              <div className="admineditcategory_row2_row_row">
                <div className="admineditcategory_row2_inner">
                  <div className="admineditcategory_row2_inner_title">
                    <div className="admineditcategory_row2_inner_title_label">
                      <label>Main Parameters</label>
                    </div>
                    <div className="admineditcategory_row2_inner_title_icon">
                      <BsThreeDots
                        style={{ color: "#C3CAD9", fontSize: "18px" }}
                      />
                    </div>

                  </div>
                  <div className="admineditcategory_row2_inner_row">
                    <label> Category Name</label>
                    {/* <span>:</span> */}
                    <div className="admineditcategory_row2_inner_row_input">
                      <input placeholder="Category" value={category} onChange={(e) => setCategory(e.target.value)} />
                    </div>
                  </div>
                  <div className="admineditcategory_row2_inner_row1">
                    <label> Sequence Number</label>
                    {/* <span>:</span> */}
                    <div className="admineditcategory_row2_inner_row_input">
                      <input placeholder="Sequence Number" value={sequenceno} onChange={(e) =>setSequenceno(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="admineditcategory_row2_row_col2">
                {/* <div className="admingoldrate_row2_row_col2_inner">
                  <div className="admingoldrate_col_row2_inner">
                    {array.map((itm, indx) => {
                      return (
                        <div className="admingoldrate_col_row2_inner_row">
                          <label placeholder="Amount">{itm.goldrate}</label>
                        </div>
                      )
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}