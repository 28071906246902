import "./Adminuserprofile.css";
import "./Adminuseranalytics.css";
import "./Adminuserprofiledetails.css";
import "./Adminuserlocationdetails.css";
import "./AdminTitle.css";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import image from "./Images/adminuserprofile_image1.png";
import { BsThreeDots } from "react-icons/bs";
import Adminuserlocation from "./Adminuserlocation";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HiUsers } from "react-icons/hi";
import config from "../config.json";
import Adminloader from "./Adminloader";

function AdminUserProfile() {
  const baseurl = config.url;
  const [show, setShow] = useState(false)
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [usrtype, setUsrtype] = useState("");
  const [email, setEmail] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [password, setPassword] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [userarray, setUserarray] = useState([]);
  const [user, setUser] = useState("");
  const [updateduserarray, setUpdateduserarray] = useState([]);
  const [updateduser, setUpdateduser] = useState("");
  const navigate = useNavigate();
  var i = localStorage.getItem("Id");
  useEffect(() => {
    setShow(true);
    console.log("User id1..." + i);
    var URL = baseurl + "adminsingleuserfetch";
    var Request = { id: i };
    var Headers = {};
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        setShow(false);
        setUserarray(res.data);
        setFirstname(res.data[0].firstname);
        setLastname(res.data[0].lastname);
        setUsrtype(res.data[0].usrtype);
        setEmail(res.data[0].email);
        setPhoneno(res.data[0].phoneno);
        setPassword(res.data[0].password);
        setState(res.data[0].state);
        setCity(res.data[0].place);
        setPincode(res.data[0].postalno);
        setAddress(res.data[0].usraddress);
        setLandmark(res.data[0].landmark);
      })
      .catch();
  }, []);

  function handleProfileeditClick() {
    // var URL = baseurl + "adminedituser";
    // var Request = {
    //   firstname: firstname,
    //   lastname: lastname,
    //   usrtype: usrtype,
    //   email: email,
    //   phoneno: phoneno,
    //   password: password,
    //   id: i,
    // };
    // var Headers = {};
    // axios
    //   .post(URL, Request, Headers)
    //   .then((res) => {
    //     console.log("updated user details..." + JSON.stringify(res.data));
    //     setUpdateduserarray(res.data);
    navigate("/Adminuserlistpage");
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  }
  return (
    <>
      <div className="adminuserprofile_loader">
        <Adminloader show={show} setShow={setShow} />
      </div>
      <div className="adminprofileeditcom">
        <div className="adminprofileeditcom_row1">
          <div className="AdminTitle_Titlemenu">
            <div className="AdminTitle_Titlemenu_col1">
              <div className="AdminTitle_Titmenu_col1_col1">
                <icon>
                  <HiUsers
                    style={{
                      color: "#C3CAD9",
                      fontsize: 70,
                      width: 20,
                      height: 20,
                      textAlign: "center",
                    }}
                  />
                </icon>
              </div>
              <div className="AdminTitle_Titmenu_col1_col2">
                {/* {userarray.map((itm,indx)=>{
                  return( */}
                <label>Profile</label>
                {/* )
                })} */}
              </div>
            </div>
            {/* <div className="AdminTitle_Titlemenu_col2">
              <div className="AdminTitle_Titmenu_col2_co1">
                <label>Profile</label>
              </div>
              <div className="AdminTitle_Titmenu_col2_co1">
                <label>Orders</label>
              </div>
              <div className="AdminTitle_Titmenu_col2_co1">
                <label>Statistics</label>
              </div>
            </div> */}
            <div className="AdminTitle_Titlemenu_col3">
              <div className="AdminTitle_Titmenu_col3_iconbutton">
                <MdAddCircle style={{ color: "#C499FF", fontSize: "20px" }} />
                <button onClick={(e) => handleProfileeditClick()} className="AdminTitle_Titlemenu_col3_button">
                  Save Users
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="adminprofileeditcom_row2">
          <div className="adminprofileeditcom_row2_col1">
            <div className="AdminUserProfile_container">
              <div className="AdminUserProfile_inner_container">
                <div className="AdminUserProfile_inn_cont_row1">
                  <img src={image}></img>
                </div>
                <div className="AdminUserProfile_inn_cont_row2">
                  <label>Nancy Powell</label>
                </div>
                <div className="AdminUserProfile_inn_cont_row3">
                  <label>c10001</label>
                </div>
              </div>
            </div>
            <div className="adminuseranalytics">
              <div className="adminuseranalytics_header">
                <div className="adminuseranalytics_header_left">
                  <label>Analytics</label>
                </div>
                <div className="adminuseranalytics_header_right">
                  <BsThreeDots />
                </div>
              </div>
              <div className="adminuseranalytics_cont">
                <Adminuseranalytics_details
                  column1={"$1456"}
                  column2={"Total Sales"}
                ></Adminuseranalytics_details>
                <Adminuseranalytics_details
                  column1={"14"}
                  column2={"Products"}
                ></Adminuseranalytics_details>
                <Adminuseranalytics_details
                  column1={"$265"}
                  column2={"Profit Sales"}
                ></Adminuseranalytics_details>
                <Adminuseranalytics_details
                  column1={"78%"}
                  column2={"Completed"}
                ></Adminuseranalytics_details>
              </div>
            </div>
          </div>
          <div className="adminprofileeditcom_row2_col2">
            <div className="adminuserprofiledetails">
              <div className="adminuserprofiledetails_top">
                <div className="adminuserprofiledetails_top_header">
                  <label>Profile</label>
                </div>
                <div className="adminuserprofiledetails_top_icon">
                  {/* < BsThreeDots style={{ fontSize: "20" }} /> */}
                  {/* <button onClick={(e) => handleProfileeditClick()}>
                    Save User
                  </button> */}
                </div>
              </div>

              <div className="adminuserprofiledetails_body">
                <Adminuserprofiledetailsl_list
                  label1="First Name"
                  placeholder1="First Name"
                  value1={firstname}
                  variable1={(e) => setFirstname(e.target.value)}
                  label2="Second Name"
                  placeholder2="Second Name"
                  value2={lastname}
                  variable2={(e) => setLastname(e.target.value)}
                />
                <Adminuserprofiledetailsl_list
                  label1="User Type"
                  placeholder1="User Type"
                  value1={usrtype}
                  variable1={(e) => setUsrtype(e.target.value)}
                  label2="Contacts"
                  placeholder2="Contacts"
                  value2={phoneno}
                  variable2={(e) => setPhoneno(e.target.value)}
                />
                {/* <Adminuserprofiledetailsl_list
                  label1="Email"
                  placeholder1="nancy-pw@gmail.com"
                  value1={email}
                  variable1={(e) => setEmail(e.target.value)}
                  label2="Password"
                  placeholder2="password"
                  value2={password}
                  variable2={(e) => setPassword(e.target.value)}
                /> */}
                <Adminuserlocationdetailsl_list
                  label1="Email"
                  placeholder1="Email"
                  value={email}
                  variable={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="adminprofileeditcom_row2_col2_innercol">
              <div className="adminuserlocationdetails">
                <div className="adminuserlocationdetails_top">
                  <div className="adminuserlocationdetails_top_header">
                    <label>Location</label>
                  </div>
                  <div className="adminuserlocationdetails_top_icon">
                    <BsThreeDots style={{ fontSize: "20" }} />
                  </div>
                </div>

                <div className="adminuserlocationdetails_body">
                  <div className="adminuserlocationdetails_body_col1">
                    <Adminuserlocationdetailsl_list
                      label1="Landmark"
                      placeholder1="Landmark"
                      value={landmark}
                      variable={(e) => setLandmark(e.targe.value)}
                    />
                    <Adminuserlocationdetailsl_list
                      label1="State"
                      placeholder1="State"
                      value={state}
                      variable={(e) => setState(e.targe.value)}
                    />
                    {/* <div className="adminuserlocationdetails_body_col2_inner"> */}
                    <Adminuserlocationdetailsl_list
                      label1="City"
                      placeholder1="City"
                      value={city}
                      variable={(e) => setCity(e.targe.value)}
                    />
                    {/* </div> */}
                  </div>
                  <div className="adminuserlocationdetails_body_col2">
                    <Adminuserlocationdetailsl_list
                      label1="Pin Code"
                      placeholder1="Pin Code"
                      value={pincode}
                      variable={(e) => setPincode(e.targe.value)}
                    />
                    <div className="adminuserlocationdetails_body_col2_inner">
                      <Adminuserlocationdetailsl_list
                        label1="Address"
                        placeholder1="Address"
                        value={address}
                        variable={(e) => setAddress(e.targe.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="adminprofileeditcom_row2_col2_innercol_map">
                <Adminuserlocation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminUserProfile;

function Adminuseranalytics_details({ column1, column2 }) {
  return (
    <>
      <div className="adminuseranalytics_details">
        <div className="adminuseranalytics_details_col1">
          <label readOnly={true}>{column1}</label>
        </div>
        <div className="adminuseranalytics_details_col2">
          <label readOnly={true}>{column2}</label>
        </div>
      </div>
    </>
  );
}

function Adminuserprofiledetailsl_list({
  label1,
  placeholder1,
  input1,
  label2,
  placeholder2,
  input2,
  value1,
  value2,
  variable1,
  variable2,
}) {
  return (
    <>
      <div className="adminprofiledetailsheader_list">
        <div className="adminprofiledetails_list_l1">
          <label>{label1}</label>
        </div>
        <div className="adminprofiledetails_list_l2">
          <input
            type="text"
            placeholder={placeholder1}
            value={value1}
            onChange={variable1}
            style={{ color: "#74829F" }}
            readOnly={true}
          >
            {input1}
          </input>
        </div>
        <div className="adminprofiledetails_list_l3">
          <label>{label2}</label>
        </div>
        <div className="adminprofiledetails_list_l4">
          <input
            type="text"
            placeholder={placeholder2}
            value={value2}
            onChange={variable2}
            readOnly={true}
          >
            {input2}
          </input>
        </div>
      </div>
    </>
  );
}

function Adminuserlocationdetailsl_list({ label1, placeholder1, input1, value, variable }) {
  return (
    <>
      <div className="adminlocationdetailsheader_list">
        <div className="adminlocationdetails_list_l1">
          <label>{label1}</label>
        </div>
        <div className="adminlocationdetails_list_l2">
          <input
            type="text"
            placeholder={placeholder1}
            value={value}
            style={{ color: "#74829F" }}
            onChange={variable}
            readOnly={true}
          >
            {input1}
          </input>
        </div>
      </div>
    </>
  );
}
