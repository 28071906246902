import "../style.css";
import AdminLeftBar from "../Components/AdminLeftBar";
import AdminTitle from "../Components/AdminTitle";
import AdminTopBar from "../Components/Admintopbar";
import Adminuseranalytics from "../Components/Adminuseranalytics";
import Adminuserlocation from "../Components/Adminuserlocation";
import Adminuserlocationdetails from "../Components/Adminuserlocationdetails";
import AdminUserProfile from "../Components/Adminuserprofile";
import Adminuserprofiledetails from "../Components/Adminuserprofiledetails";
import { HiUsers } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import "./Adminprofileeditpage.css";
import Adminmenubar from "../Components/Adminmenubar";
import Adminloader from "../Components/Adminloader";

export default function Adminprofileeditpage() {
  const navigate = useNavigate();
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage");
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handleAddproductClick = () => {
    navigate("/Adminaddproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }

  return (
    <>

      <div className="adminprofileeditpage">
        <div className="adminprofileeditpage_top">
          <AdminTopBar
            title={"Edit Profile"}
          />
          {/* <Adminmenubar /> */}
        </div>
        <div className="adminprofileeditpage_body">
          <div className="adminprofileeditpage_body__left">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="adminprofileeditpage_body_right">
            <div className="adminprofileeditpage_body_right_title">
              <AdminUserProfile />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
