import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminaddsavetitle from "../Components/Adminaddsavetitle";
import Admineditsubtitle from "../Components/Admineditsubtitle";
import Adminaddproductmain from "../Components/Adminaddproductmain";
import Adminloader from "../Components/Adminloader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./Adminaddproductpage.css";
import config from "../config.json";
import Adminaddmedia from "../Components/Adminaddmedia";
import Adminaddsubtitle from "../Components/Adminaddsubtitle";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminaddproductpage() {
  const navigate = useNavigate();
  const baseurl = config.url;
  const [productarray, setProductarray] = useState([]);
  const [product, setProduct] = useState("");
  const [prdname, setPrdname] = useState("");
  const [color, setColor] = useState("");
  const [prdstyle, setPrdstyle] = useState("");
  const [style, setStyle] = useState([]);
  const [prdcode, setPrdcode] = useState("");
  const [prdtype, setPrdtype] = useState("");
  const [weartype, setWearType] = useState([]);
  const [prdmetal, setPrdmetal] = useState("");
  const [metal, setMetal] = useState([]);
  const [price, setPrice] = useState("");
  const [weight, setWeight] = useState("");
  const [prddescription, setPrddescription] = useState("");
  const [prdcategory, setPrdcategory] = useState("");
  const [category, setcategory] = useState([]);
  const [prdSubCategory, setPrdSubCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [sequenceno, setSequenceno] = useState("");
  const [updatedproductarray, setUpdatedproductarray] = useState([]);
  const [featuredproduct, setFeaturedproduct] = useState("0");
  const [topcategories, setTopcategories] = useState("0");
  const [bestseller, setBestseller] = useState("0");
  const [latestproducts, setLatestProducts] = useState("0");
  const [show, setShow] = useState(false);
  const [selectedImages, setSelectedImage] = useState([]);
  const [selectedImages1, setSelectedImage1] = useState([]);
  const [currentupdate1, setCurrentupdate1] = useState([]);
  const [currentupdate, setCurrentupdate] = useState([]);
  const [GST, setGST] = useState("");
  const [stone, setStone] = useState("");
  const [sWeight, setSWeight] = useState("");
  const [gWeight, setGWeight] = useState("");
  const [nWeight, setNWeight] = useState("");
  const [mCharge, setMCharge] = useState("Percentage");
  const [sCharge, setSCharge] = useState("Percentage");
  const [priceCat, setPriceCat] = useState("916");
  const [file, setFile] = useState([]);
  const [file1, setFile1] = useState([]);
  const [tabMenus, setTabMenus] = useState([
    {
      id: 1,
      name: "Main",
      selected: true,
    },
    {
      id: 2,
      name: "Media",
      selected: false,
    },
  ]);

  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage");
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  };
  // var i = localStorage.getItem("prdid");
  // var URL = baseurl + "adminsingleproductfetch";
  // var Request = { id: i };
  // console.log("product id" + JSON.stringify(Request));
  // var Headers = {};
  useEffect(() => {
    setShow(true);

    var url1 = baseurl + "adminfetchcategory";
    var req1 = {};
    var header1 = {};
    axios
      .post(url1, req1, header1)
      .then((res) => {
        setShow(false);
        setcategory(res.data);
      })
      .catch();
    var url2 = baseurl + "adminfetchsubcategory";
    var req2 = {};
    var header2 = {};
    axios
      .post(url2, req2, header2)
      .then((res) => {
        // setShow(false);
        setSubCategory(res.data);
      })
      .catch();

    var url3 = baseurl + "adminfetchweartype";
    var req3 = {};
    var header3 = {};
    axios
      .post(url3, req3, header3)
      .then((res) => {
        setWearType(res.data);
      })
      .catch();

    var url4 = baseurl + "adminfetchstyle";
    var req4 = {};
    var header4 = {};
    axios
      .post(url4, req4, header4)
      .then((res) => {
        setStyle(res.data);
      })
      .catch();

    var url5 = baseurl + "adminfetchmetal";
    var req5 = {};
    var header5 = {};
    axios
      .post(url5, req5, header5)
      .then((res) => {
        setMetal(res.data);
      })
      .catch();
  }, []);

  function handleProductAddClick() {
    const status = localStorage.getItem("addstatus");
    setShow(true)
    //  const curTime = new Date().toLocaleString()
    const curTime = new Date().toISOString().slice(0, 19).replace("T", " ");
    // if (file.length > 0) {
    var URL = baseurl + "adminaddproduct";
    if (status) {
      var Request = {
        prdname: prdname,
        prdcode: prdcode,
        color: color,
        prdcategory: prdcategory,
        prdsubcategory: prdSubCategory,
        prdstyle: prdstyle,
        prdtype: prdtype,
        prdmetal: prdmetal,
        price: price,
        weight: weight,
        prddescription: prddescription,
        cdate: curTime,
        pname: prdname,
        ctdate: curTime,
        seqno: sequenceno,
        latestflag: latestproducts,
        bestflag: bestseller,
        featuredflag: featuredproduct,
        categoryflag: topcategories,
        stonecharge: stone,
        GST: GST,
        stonetype: sCharge,
        costtype: mCharge,
        mainimg: file1,
        img: selectedImages,
        pricecat: priceCat,
        sweight: sWeight,
        gweight: gWeight,
        nweight: nWeight,
        status: status,
      };
    } else {
      var Request = {
        prdname: prdname,
        prdcode: prdcode,
        color: color,
        prdcategory: prdcategory,
        prdsubcategory: prdSubCategory,
        prdstyle: prdstyle,
        prdtype: prdtype,
        prdmetal: prdmetal,
        price: price,
        weight: weight,
        prddescription: prddescription,
        cdate: curTime,
        pname: prdname,
        ctdate: curTime,
        seqno: sequenceno,
        latestflag: latestproducts,
        bestflag: bestseller,
        featuredflag: featuredproduct,
        categoryflag: topcategories,
        stonecharge: stone,
        GST: GST,
        stonetype: sCharge,
        costtype: mCharge,
        mainimg: file1,
        img: selectedImages,
        pricecat: priceCat,
        sweight: sWeight,
        gweight: gWeight,
        nweight: nWeight,
        status: 1
      };
    }
    console.log("prd add req==>" + JSON.stringify(Request));
    var Headers = {};
    // if (file1.length > 0 && selectedImages.length > 0) {

      axios
        .post(URL, Request, Headers)
        .then((res) => {
          setShow(false)
          const a = res.data;
          localStorage.setItem("insertid", a);
          // setProductarray(res.data);
          alert("Successfully Inserted!!")
          navigate("/Adminproductlistpage");
          localStorage.removeItem("addstatus");
        })
        .catch((err)=>{
          alert("Failed to Insert!!")
        });
   
    // var imgid = localStorage.getItem("insertid");
    // var URL = baseurl + "admininserimages";
    // // var URL="";
    // var Request = {
    //   img: selectedImages,
    //   prdid: imgid,
    // };
    // // console.log(Request);
    // var Headers = {};
    // axios
    //   .post(URL, Request, Headers)
    //   .then((res) => {
    //     console.log("Updated Product" + JSON.stringify(res.data));
    //     setCurrentupdate(res.data);
    //     navigate("/Adminproductlistpage");
    //   })
    //   .catch();

    // var url = baseurl + "admininsermainimage";
    // // var url="";
    // var req = {
    //   img: file1,
    //   prdid: imgid,
    // };
    // // console.log(Request);
    // var header = {};
    // axios
    //   .post(url, req, header)
    //   .then((res) => {
    //     console.log("Updated Product" + JSON.stringify(res.data));
    //     setCurrentupdate(res.data);
    //     navigate("/Adminproductlistpage");
    //   })
    //   .catch();
    // } else {
    //   alert("Insert Image")
    // }
  }

  const handleUploadChange = (e) => {
    if (e.target.files.length != 0 && e.target.files.length <= 1) {
      console.log("image name==>", e.target.files);
      const MAX_FILE_SIZE = 10 // 5MB

      //   const { offsetHeight, offsetWidth } = e.target.files;
      // console.log("img Diamention==>"+offsetHeight, offsetWidth);
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      console.log("Array==>", selectedFilesArray);
      // const imageArray = selectedFilesArray.map((file) => {
      //   console.log(file)
      //   return URL.createObjectURL(file);
      // });
      // setSelectedImage1((previousImages) => previousImages.concat(imageArray));
      // // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
      // function toDataURL(url, callback) {
      //   var xhr = new XMLHttpRequest();
      //   xhr.onload = function () {
      //     var reader = new FileReader();
      //     reader.onloadend = function () {
      //       callback(reader.result);
      //     };
      //     reader.readAsDataURL(xhr.response);
      //   };
      //   xhr.open("GET", url);
      //   xhr.responseType = "blob";
      //   xhr.send();
      // }

      let reader = new FileReader();
      const imageArray = selectedFilesArray.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log("image:" + reader.result);

          //           var length = reader.result.AsSpan().Slice(reader.result.IndexOf(',') + 1).Length;

          //           var fileSizeInByte = Math.Ceiling((double).length / 4) * 3;
          console.log("img size==>" + reader.result.length)
          const imglength = reader.result.length / 1000;
          if (imglength < 300) {
            setFile1(reader.result)
          } else {
            alert("Image size limit is upto 300kb")
          }

        };
      });

      // toDataURL(selectedImages1, function (dataUrl) {
      //   console.log("RESULT:", dataUrl);
      //   setFile1(dataUrl);
      // });
    }
  };
  const handleImagesUploadChange = (e) => {
    if (selectedImages.length < 3) {
      // const c= e.target.files.length;
      // console.log("main img length==>",c);
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      console.log("Array==>", selectedFilesArray);
      // const imageArray = selectedFilesArray.map((file) => {
      //   return URL.createObjectURL(file);
      // });
      // setSelectedImage((previousImages) => previousImages.concat(imageArray));
      // // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
      // const c = selectedImages.length + 1;
      // console.log("sub img length==>", selectedImages);
      // localStorage.setItem("sublength", c);
      // function toDataURL(url, callback) {
      //   var xhr = new XMLHttpRequest();
      //   xhr.onload = function () {
      //     var reader = new FileReader();
      //     reader.onloadend = function () {
      //       callback(reader.result);
      //     };
      //     reader.readAsDataURL(xhr.response);
      //   };
      //   xhr.open("GET", url);
      //   xhr.responseType = "blob";
      //   xhr.send();
      // }

      // toDataURL(selectedImages, function (dataUrl) {
      //   console.log("RESULT:", dataUrl);
      //   setFile(dataUrl);
      // });
      let reader = new FileReader();
      const imageArray = selectedFilesArray.map((file) => {
        reader.readAsDataURL(file);
        reader.onload = function () {
          console.log("image:" + reader.result);
          // setFile(reader.result);
          console.log("img size==>" + reader.result.length)
          const imglength = reader.result.length / 1000;
          if (imglength < 300) {
            setSelectedImage((previousImages) => previousImages.concat(reader.result));
          } else {
            alert("Image size limit is upto 300kb")
          }
        };
      });
      //  setSelectedImage((previousImages) => previousImages.concat(imageArray));
      //  console.log("selected array==>"+selectedImages)
    } else {
      alert("Maximum 3 subimages")
    }
  };

  const [checked, setChecked] = useState(true);

  const handleStatusChange = (e) => {

    setChecked(current => !current);
    console.log("checked==>" + checked);
    if (checked !== true) {
      console.log("status==>" + 1);
      localStorage.setItem("addstatus", 1)
    } else {
      console.log("status==>" + 0);
      localStorage.setItem("addstatus", 0);
    }
  };

  function notify() {
    // Get the snackbar DIV
    var x = document.getElementById("snackbar");
    // Add the "show" class to DIV
    x.className = "show";
    // After 3 seconds, remove the show class from DIV
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
    // window.location.reload(false);
  }


  return (
    <>
      <div className="adminaddproduct">
        <Admintopbar title={"Add Product"} />
        {/* <Adminmenubar/> */}
        <div className="adminaddproduct_row">
          <div className="adminaddproduct_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="admineditproductpage_loader">
          <Adminloader show={show} />
          </div>
          <div className="adminaddproduct_row_col2">
            <Adminaddsavetitle
              content={"Save product"}
              handleProductAddClick={handleProductAddClick}
              handleStatusChange={handleStatusChange}
              checked={checked}
              file1={file1}
              selectedImages={selectedImages}
              // notify={notify}
            />
            <Adminaddsubtitle tabMenus={tabMenus} setTabMenus={setTabMenus} />
            {tabMenus[0].selected ? (
              <Adminaddproductmain
                featuredproduct={featuredproduct}
                setFeaturedproduct={setFeaturedproduct}
                latestproducts={latestproducts}
                setLatestProducts={setLatestProducts}
                topcategories={topcategories}
                setTopcategories={setTopcategories}
                bestseller={bestseller}
                setBestseller={setBestseller}
                prdname={prdname}
                setPrdname={setPrdname}
                product={product}
                setProduct={setProduct}
                color={color}
                setColor={setColor}
                prdstyle={prdstyle}
                setPrdstyle={setPrdstyle}
                prdcode={prdcode}
                setPrdcode={setPrdcode}
                prdtype={prdtype}
                setPrdtype={setPrdtype}
                prdmetal={prdmetal}
                setPrdmetal={setPrdmetal}
                price={price}
                setPrice={setPrice}
                weight={weight}
                setWeight={setWeight}
                prddescription={prddescription}
                setPrddescription={setPrddescription}
                prdcategory={prdcategory}
                setPrdcategory={setPrdcategory}
                prdSubCategory={prdSubCategory}
                setPrdSubCategory={setPrdSubCategory}
                sequenceno={sequenceno}
                setSequenceno={setSequenceno}
                setUpdatedproductarray={setUpdatedproductarray}
                style={style}
                setStyle={setStyle}
                weartype={weartype}
                metal={metal}
                setMetal={setMetal}
                category={category}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                setcategory={setcategory}
                setWearType={setWearType}
                productarray={productarray}
                setProductarray={setProductarray}
                GST={GST}
                setGST={setGST}
                stone={stone}
                setStone={setStone}
                mCharge={mCharge}
                setMCharge={setMCharge}
                sCharge={sCharge}
                setSCharge={setSCharge}
                priceCat={priceCat}
                setPriceCat={setPriceCat}
                sWeight={sWeight}
                setSWeight={setSWeight}
                gWeight={gWeight}
                setGWeight={setGWeight}
                nWeight={nWeight}
                setNWeight={setNWeight}
              />
            ) : (
              <Adminaddmedia
                handleUploadChange={handleUploadChange}
                handleImagesUploadChange={handleImagesUploadChange}
                selectedImages={selectedImages}
                setSelectedImage={setSelectedImage}
                selectedImages1={selectedImages1}
                setSelectedImage1={setSelectedImage1}
                setFile1={setFile1}
                file1={file1}
                file={file}
                setFile={setFile}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
