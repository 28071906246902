import './Adminsavetitle.css';
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md"
import axios from 'axios';
import { type } from '@testing-library/user-event/dist/type';
import { useState } from 'react';
import config from "../config.json";

export default function Adminsavetitle({ content, handleProductAddClick,handleStatusChange,checked,notify,file1,selectedImages,label }) {
    const baseurl = config.url;
    // const[currentinsert,setCurrentinsert]=useState({

    //     "prdname":"Mine Platinum Casual Pendant ",
    //     "prdcode":"UIPD24194PT","color":"Platinum",
    //     "prdcategory":"Pendent","prdstyle":"casual",
    //     "prdtype":"Office wear","prdmetal":"Platinum",
    //     "price":"21861",
    //     "weight":"2.1200",
    //     "prddescription":"Free Shipping In India | Hallmarked jewellery available for sale.",
    //     "prdimg":null,
    //     "deleted":"0",
    //     "featuredflag":"0",
    //     "topcategoryflag":"1"
    // });
    // function handleProducteditClick(){

    //     var URL="http://localhost:8000/addproduct";
    //     var Request={prdname:currentinsert.prdname,prdcode:currentinsert.prdcode,color:currentinsert.color,prdcategory:currentinsert.prdcategory,prdstyle:currentinsert.prdstyle,prdtype:currentinsert.prdtype,prdmetal:currentinsert.prdmetal,price:currentinsert.price,weight:currentinsert.weight,prddescription:currentinsert.prddescription,id:currentinsert.id};
    //     console.log(Request)
    //     var Headers={};
    //     axios.post(URL,Request,Headers)
    //          .then((res)=>{
    //             console.log("Updated Product" + JSON.stringify(res.data))
    //             (res.data);
    //          })
    //          .catch()
    // }
    const [status,setStatus]=useState(1);
    // const [checked,setChecked]=useState(true);

    // const handleStatusChange=(e)=>{
            
    //         setChecked(current=>!current);
    //         console.log("checked==>"+checked);
    //     if(checked!==true){
    //         console.log("status==>"+1);
    //     }else{
    //         console.log("status==>"+0);
    //     }
    // };

    return (
        <>
            <div className="adminaddsaveitle_Titlemenu">
                <div className="adminaddsaveitle_Titlemenu_col1">
                    <div className="adminaddsaveitle_Titmenu_col1_col1">
                        <label><MdShoppingBasket style={{ color: '#C3CAD9', fontsize: 70, width: 25, height: 25, textAlign: 'center' }} /></label>
                    </div>
                    <div className="adminaddsaveitle_Titmenu_col1_col2">
                        <label>Products</label>
                    </div>
                </div>
                <div className="adminaddsaveitle_Titlemenu_col3">
                    <div className='adminaddsaveitle_Titlemenu_col3_toggle'>
                        <div className="toggle-switch">
                            <input type="checkbox" className="checkbox"
                                name="toggle" id="toggle"
                                // value={status} 
                                checked={checked}
                                onChange={(e)=>handleStatusChange(e)}
                                />
                            <label className="label" htmlFor="toggle">
                                <span className="inner" />
                                <span className="switch" />
                            </label>
                        </div>
                    </div>
                    <div className="adminaddsaveitle_Titmenu_col3_iconbutton">
                        <MdAddCircle style={{ color: '#C499FF', fontSize: '20px' }} />
                        <button onClick={(e) => { handleProductAddClick();notify() }} className="adminaddsaveitle_Titmenu_col3_iconbutton_button">{content}</button>
                        {(file1.length>0 && selectedImages.length>0) ?
                        <div id="snackbar">Saved Successfully</div>
                        :
                        <div id="snackbar">  Insert Images </div>
                        } 
                    </div>
                </div>
            </div>
        </>
    )
};