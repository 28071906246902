import "./Admineditsubtitle.css";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Fragment } from "react";

export default function Adminaddsubtitle({tabMenus, setTabMenus}) {
  //   const[file,setFile]=useState();
  // function fileSelectedHandler(event){
  //    console.log(event.target.files[0])
  //    setFile(URL.createObjectURL(event.target.files[0]))
  // }

  const navigate = useNavigate();
  // const {selectedTabId} = props;
  // const [counter, setCounter] = useState(0);


  // useEffect(() => {
  //   const initialMenus = initialiseAndReturnTabMenu();
  //   if(selectedTabId){
  //     let selectedMenu = initialMenus.find(menu=> menu.id== selectedTabId);
  //     selectedMenu.selected = true;
  //   }
  //   setTabMenus(initialMenus);
  // }, []);

  // const onTabClicked = (tabMenu, incomingtabMenus, inComingCounter) => {
  //   incomingtabMenus.forEach(menu => {
  //     if (menu.id === tabMenu.id) {
  //       menu.selected = true;
  //     } else {
  //       menu.selected = false;
  //     }
  //   });
  //   let counterInc = inComingCounter + 1;
  //   setTabMenus(incomingtabMenus);
  //   setCounter(counterInc);
  //   if(tabMenu.onClick){
  //     tabMenu.onClick();
  //   }
  // }

  // const initialiseAndReturnTabMenu = () => {
  //   return [{
  //     id:1,
  //     name: "Main",
  //     onClick: ()=> {handleProducteditClick()},
  //     selected: false
  //   }, {
  //     id:2,
  //     name: "Media",
  //     onClick: ()=> {handlemediaClick();},
  //     selected: false
  //   },
  // ];
  // }
  function handlemediaClick() {
    navigate('/Adminaddproductmediapage');
  }
  function handleProducteditClick(){
    navigate('/Adminaddproductpage')
  }
const onTabClicked=(menu,indx)=>{
  let temp=[...tabMenus]
  for (const iterator of temp){
    iterator.selected=false;
  }
  temp[indx].selected=true;
  setTabMenus(temp);
}

  return (
    <>
      <div className="admineditsubtitle">
        <div className="admineditsubtitle_Titlemenu">
          <Fragment>
            <div className="admineditsubtitle_Titlemenu_col2">
              {tabMenus.map((menu,indx) => (
                <div key={menu.name} className="admineditsubtitle_Titmenu_col2_co1">
                  <label onClick={() => onTabClicked(menu,indx)} className={menu.selected ? "selected" : ""}>{menu.name}</label></div>))}
            </div>
          </Fragment>
        </div>
      </div>
    </>
  );
}