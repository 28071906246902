import "./AdminLogin.css";
import AdminLogin_mail from "./Images/AdminLogin_mail.jpg";
import AdminLogin_hand12 from "./Images/AdminLogin_hand12.png";
import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import config from "../config.json";
import {useDispatch,useSelector} from "react-redux";
// import {ReactSession} from "react-client-session";
// import {ReactSession} from "react-client-session";
function AdminLogin() {
  const baseurl=config.url;
  const dispatch=useDispatch();
  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();
  const [errormessage, setErrormessage] = useState("");
  const [errorMessage,setErrorMessage]=useState("");
  // function handleClick(){
  //     setErrormessage=("Error Message");
  // }
  // var email=document.getElementById("mail");
  // email.addEventListener("key up",function(event){
  //     if(email.addEventListener("keyup",))
  // })
  const handleLoginClick = (e, handleClick) => {
    e.preventDefault();
    // var URL=baseurl+"adminlogin";
    var URL=baseurl+"adminloginjwt";
    var Request = {
      email: mail,
      password: pwd,
    };
    var Headers = {
      //     'Authentication': `Basic ${mail}:${pwd}`,
      // 'Content-Type': 'application/json',
      // 'mode': 'no-cors'
    };
    axios
      .post(URL, Request, Headers)
      .then((res) => {
        if (res.data == "Username empty") {
          // alert("Username empty");
          // <handleClick/>
        } else if (res.data == "Password empty") {
          alert("Password empty");
        } else if (res.data == "") {
          alert("Incorrect username or password");
        } else {
          if (res.data.token == "") {
            setErrorMessage("Check username or password !!");
          } else {
            var tok = res.data.token;
            console.log("tok : " + tok);
            console.log("user : " + JSON.stringify(res.data.user));
            dispatch({type:"setToken",payload:tok})
            dispatch({type:"setUsr",payload:res.data.user})
            // ReactSession.set("username", mail);
            // ReactSession.set("password", pwd);
            navigate("/Admindashboardpage");
          }
          // navigate("/Admindashboardpage");
        }
      })
      .catch();
  };
  return (
    <div>
      <div className="AdminLogin_outer">
        <div className="AdminLogin_container">
          <div className="AdminLogin_inner_container">
            <div className="AdminLogin_inncont_row1">
              <img src={AdminLogin_mail}></img>
              <label>Logo</label>
            </div>
            <div className="AdminLogin_inncont_row2">
              <label>Welcome!</label>
              {/* <img src={AdminLogin_hand12}></img> */}
            </div>
            <div className="AdminLogin_inncont_row3">
              <label>Please sign-in to your account</label>
            </div>
            <div className="AdminLogin_inncont_row4">
              <input
                // type="Name"
                placeholder="Email id"
                onChange={(e) => {
                  setMail(e.target.value);
                }}
              ></input>
            </div>
            <div className="AdminLogin_inncont_row5">
              <input
                type="password"
                id="email"
                name="email"
                placeholder="Password"
                onChange={(e) => {
                  setPwd(e.target.value);
                }}
              ></input>
              {/* <AiOutlineEye /> */}
            </div>
            <div className="AdminLogin_inncont_row6">
              <div className="AdminLogin_inncont_check_row1_col1">
                <input type="checkbox"></input>
                <label>Remember me</label>
              </div>
              <div className="AdminLogin_inncont_check_row1_col2">
                <label>
                  <span>Forget Password?</span>
                </label>
              </div>
            </div>
            <div className="AdminLogin_inncont_row7">
              <button
                onClick={(e) => {
                  handleLoginClick(e);
                }}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminLogin;
