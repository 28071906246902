import React from "react";
import "./Admingoldrate.css";
import "./Adminaddsavetitle.css";
import { FaClipboardList } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import config from "../config.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Adminloader from "./Adminloader";


export default function Admingoldrate() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [array, setArray] = useState([]);
  const [show,setShow]=useState(false)
  const [amountArray, setAmountArray] = useState([]);
  const [amountHistoryArray,setAmountHistoryArray]=useState([]);
  const [amount, setAmount] = useState("");
  const [carat,setCarat]=useState("");
  useEffect(() => {
    setShow(true);
    const url = baseurl + "fetchgoldrate";
    const req = {};
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setArray(res.data);
        setAmount(res.data[0].goldrate);
        setCarat(res.data[0].lowgoldrate);
      })
      .catch();
  },[]);

  const handleProducteditClick = () => {
    setShow(true);
    const url = baseurl + "updategoldrate";
    const req = { rate: amount,carat:carat };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setAmountArray(res.data);
        alert("Successfully updated!")
        // window.location.reload();
      })
      .catch();

    //   const url1 = baseurl + "admininsertgoldrates";
    // const req1 = { rate: amount,carat:carat };
    // const header1 = {};
    // axios
    //   .post(url1, req1, header1)
    //   .then((res) => {
    //     setAmountHistoryArray(res.data);
    //     // navigate("/admindashboard");
    //     // window.location.reload();
    //   })
    //   .catch();
  }
  return (
    <>
    <div className="admingoldrate_loader">
      <Adminloader show={show}/>
    </div>
      <div className="admingoldrate">
        <div className="admingoldrate_inner">
          <div className="admingoldrate_row1">
            <div className="adminsaveitle_Titlemenu">
              <div className="adminsaveitle_Titlemenu_col1">
                <div className="adminsaveitle_Titmenu_col1_col1">
                  <label>
                    <FaClipboardList
                      style={{
                        color: "#C3CAD9",
                        fontsize: 70,
                        width: 25,
                        height: 25,
                        textAlign: "center",
                      }}
                    />
                  </label>
                </div>
                <div className="adminsaveitle_Titmenu_col1_col2">
                  <label>Settings</label>
                </div>
              </div>
              <div className="adminsaveitle_Titlemenu_col3">
                <div className="adminsaveitle_Titmenu_col3_iconbutton">
                  <MdAddCircle style={{ color: "#C499FF", fontSize: "20px" }} />
                  <button
                    onClick={(e) => {
                      handleProducteditClick();
                    }}
                    className="adminsaveitle_Titlemenu_col3_button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="admingoldrate_row2">
            <div className="admingoldrate_row2_row">
              <div className="admingoldrate_row2_row_row">
                <div className="admingoldrate_row2_inner">
                  <div className="admingoldrate_row2_inner_title">
                    <div className="admingoldrate_row2_inner_title_label">
                      <label>Main Parameters</label>
                    </div>
                    <div className="admingoldrate_row2_inner_title_icon">
                      <BsThreeDots
                        style={{ color: "#C3CAD9", fontSize: "18px" }}
                      />
                    </div>

                  </div>
                  <div className="admingoldrate_row2_inner_row">
                    <label> Gold Amount (916)</label>
                    {/* <span>:</span> */}
                    <div className="admingoldrate_row2_inner_row_input">
                      <input placeholder="Enter 916 gold rate" value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                  </div>
                  <div className="admingoldrate_row2_inner_row">
                    <label> Gold Amount (18 carat)</label>
                    {/* <span>:</span> */}
                    <div className="admingoldrate_row2_inner_row_input">
                      <input placeholder="Enter 18 carat gold rate" value={carat} onChange={(e) => setCarat(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="admingoldrate_row2_row_col2">
                {/* <div className="admingoldrate_row2_row_col2_inner">
                  <div className="admingoldrate_col_row2_inner">
                    {array.map((itm, indx) => {
                      return (
                        <div className="admingoldrate_col_row2_inner_row">
                          <label placeholder="Amount">{itm.goldrate}</label>
                        </div>
                      )
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}