import React from "react";
import "./Admingoldrate.css";
import "./Adminaddsavetitle.css";
import { FaClipboardList } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { useState } from "react";
import { useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import config from "../config.json";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Admincategorytitle from "./Admincategorytitle";
import Adminloader from "./Adminloader";

export default function Adminaddsubcategory() {
  const baseurl = config.url;
  const navigate = useNavigate();
  const [catArray, setCatArray] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [sequenceno, setSequenceno] = useState("");
  const [show, setShow] = useState(false);

  const handleAddSubcategoryClick = () => {
    setShow(true);
    const status = localStorage.getItem("addcatstatus");
    const url = baseurl + "adminaddsubcategory";
    // const url=""
    if (status) {
      var req = { subcat: subcategory, seqno: sequenceno, status: status };
    } else {
      var req = { subcat: subcategory, seqno: sequenceno, status: 1 };
    }

    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setCatArray(res.data);
        navigate("/Adminsubcategorylistpage");
        localStorage.removeItem("addcatstatus");
      })
      .catch();

  }

  const [checked, setChecked] = useState(true);

  const handleStatusChange = (e) => {

    setChecked(current => !current);
    console.log("checked==>" + checked);
    if (checked !== true) {
      console.log("status==>" + 1);
      localStorage.setItem("addcatstatus", 1)
    } else {
      console.log("status==>" + 0);
      localStorage.setItem("addcatstatus", 0);
    }
  };

  return (
    <>
      <div className="admingoldrate_loader">
        <Adminloader show={show} />
      </div>
      <div className="admineditcategory">
        <div className="admineditcategory_inner">
          <Admincategorytitle
            label1="Add Subcategory"
            icon={
              <FaClipboardList
                style={{
                  color: "#C3CAD9",
                  fontsize: 70,
                  width: 20,
                  height: 20,
                  textAlign: "center",
                }}
              />
            }
            // label2="Active"
            // label3="Draft"
            // label4="Assembly"
            label5="Add Subcategory"
            handleAddproductClick={handleAddSubcategoryClick}
            handleStatusChange={handleStatusChange}
            checked={checked}
          />
          <div className="admineditcategory_row2">
            <div className="admineditcategory_row2_row">
              <div className="admineditcategory_row2_row_row">
                <div className="admineditcategory_row2_inner">
                  <div className="admineditcategory_row2_inner_title">
                    <div className="admineditcategory_row2_inner_title_label">
                      <label>Main Parameters</label>
                    </div>
                    <div className="admineditcategory_row2_inner_title_icon">
                      <BsThreeDots
                        style={{ color: "#C3CAD9", fontSize: "18px" }}
                      />
                    </div>

                  </div>
                  <div className="admineditcategory_row2_inner_row">
                    <label> Subcategory Name</label>
                    {/* <span>:</span> */}
                    <div className="admineditcategory_row2_inner_row_input">
                      <input placeholder="Subcategory" value={subcategory} onChange={(e) => setSubcategory(e.target.value)} />
                    </div>
                  </div>
                  <div className="admineditcategory_row2_inner_row1">
                    <label> Sequence Number</label>
                    {/* <span>:</span> */}
                    <div className="admineditcategory_row2_inner_row_input">
                      <input placeholder="Sequence Number" value={sequenceno} onChange={(e) => setSequenceno(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="admineditcategory_row2_row_col2">
                {/* <div className="admingoldrate_row2_row_col2_inner">
                  <div className="admingoldrate_col_row2_inner">
                    {array.map((itm, indx) => {
                      return (
                        <div className="admingoldrate_col_row2_inner_row">
                          <label placeholder="Amount">{itm.goldrate}</label>
                        </div>
                      )
                    })}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}