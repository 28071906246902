import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {createStore, legacy_createStore} from 'redux';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const initialState = { 
    cart:[],
    total:0,
    user:[],
    wishlist:[]
};

function todoReducer(prevState = initialState, action) {
  switch (action.type) { 
    case 'setToken':
        // console.log("here"+action.payload)
      return {...prevState, token: action.payload};
      break;  
      // case 'setTotal': 
      //   return {...prevState, total: action.payload};
      //   break;  
        case 'setUsr':
          return {...prevState,Usr:action.payload};
          break;
          // case 'setWishlist':
      //       return{...prevState,wishlist:action.payload};
      //       break;
    default:
      return prevState;
  }
}

const persistedReducer = persistReducer(persistConfig, todoReducer);

export const store = legacy_createStore(persistedReducer);
export const persistor = persistStore(store);

 