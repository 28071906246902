import image from "./Images/adminuserlocation_image1.png";
import "./Adminuserlocation.css";

export default function Adminuserlocation(){
    return(
        <>
          <div className="adminuserlocation">
            <img src={image}></img>
          </div>
        </>
    )
}