import "./AdminUploadUrl.css";
import "./AdminImages.css";
import "./AdminVideo.css";
import "./Adminaddsavetitle.css";
import "./Admineditsubtitle.css";
import { IoCloseSharp } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { MdShoppingBasket } from "react-icons/md";
import { MdAddCircle } from "react-icons/md";
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";
import AdminUserProfile_Women1 from "./Images/AdminUserProfile_Women1.png";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./AdminUploadFile.css";
// import Button from "@material-ui/core/Button";
import { MdUpload } from "react-icons/md";
import { useState } from "react";
import jewellery from "./Images/Adminvideo_jewellery.jpg";
import config from "../config.json";
import Admineditsubtitle from "./Admineditsubtitle";
import Adminloader from "./Adminloader";

function AdminUploadUrl({handleUploadChange, handleImagesUploadChange, selectedImages, setSelectedImage, selectedImages1, setSelectedImage1, currentupdate, setCurrentupdate, currentupdate1, setCurrentupdate1, file1, setFile1 }) {
  const baseurl = config.url;
  const [show, setShow] = useState(false);
  // const [selectedImages, setSelectedImage] = useState([]);
  // const [selectedImages1, setSelectedImage1] = useState([]);
  // const [currentupdate, setCurrentupdate] = useState([]);
  // const [currentupdate1, setCurrentupdate1] = useState([]);
  // const [file, setFile] = useState("");
  // const [file1, setFile1] = useState("");
  const [visible, setVisible] = useState([]);
  function removeImage(id) {
    const newList = selectedImages1.filter((l) => l.id !== id);
    setSelectedImage1(newList);
  }
  function removeImage1() {
    setSelectedImage((current) =>
      current.filter((selectedImages) => {
        return selectedImages.id == 1;
      })
    );
  }
  const imagelist = [
    {
      id: 1,
      name: { selectedImages },
    },
    {
      id: 2,
      name: { selectedImages },
    },
    {
      id: 3,
      name: { selectedImages },
    },
    {
      id: 4,
      name: { selectedImages },
    },
  ];
  // const handleChange = (e) => {
  //   console.log(e.target.files);
  //   setFile(URL.createObjectURL(e.target.files[0]));
  // };
  // const handleUploadClick = (e) => {};
  // const handleUploadChange = (e) => {
  //   if (e.target.files.length != 0 && e.target.files.length <= 1) {
  //     console.log("image name==>", e.target.files);
  //     const selectedFiles = e.target.files;
  //     const selectedFilesArray = Array.from(selectedFiles);
  //     // console.log("Array==>",selectedFilesArray);
  //     const imageArray = selectedFilesArray.map((file) => {
  //       return URL.createObjectURL(file);
  //     });
  //     setSelectedImage1((previousImages) => previousImages.concat(imageArray));
  //     // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
  //     function toDataURL(url, callback) {
  //       var xhr = new XMLHttpRequest();
  //       xhr.onload = function () {
  //         var reader = new FileReader();
  //         reader.onloadend = function () {
  //           callback(reader.result);
  //         };
  //         reader.readAsDataURL(xhr.response);
  //       };
  //       xhr.open("GET", url);
  //       xhr.responseType = "blob";
  //       xhr.send();
  //     }

  //     toDataURL(selectedImages1, function (dataUrl) {
  //       console.log("RESULT:", dataUrl);
  //       setFile1(dataUrl);
  //     });
  //   }
  // };

  // const handleImagesUploadChange = (e) => {
  //   if (e.target.files.length != 0 && e.target.files.length <= 4) {
  //     console.log("image name==>", e.target.files);
  //     // const c= e.target.files.length;
  //     // console.log("main img length==>",c);
  //     const selectedFiles = e.target.files;
  //     const selectedFilesArray = Array.from(selectedFiles);
  //     // console.log("Array==>",selectedFilesArray);
  //     const imageArray = selectedFilesArray.map((file) => {
  //       return URL.createObjectURL(file);
  //     });
  //     setSelectedImage((previousImages) => previousImages.concat(imageArray));
  //     // setFile({ previewimage: URL.createObjectURL(e.target.files[0]) });
  //     const c = selectedImages.length + 1;
  //     console.log("sub img length==>", selectedImages);
  //     localStorage.setItem("sublength", c);
  //     function toDataURL(url, callback) {
  //       var xhr = new XMLHttpRequest();
  //       xhr.onload = function () {
  //         var reader = new FileReader();
  //         reader.onloadend = function () {
  //           callback(reader.result);
  //         };
  //         reader.readAsDataURL(xhr.response);
  //       };
  //       xhr.open("GET", url);
  //       xhr.responseType = "blob";
  //       xhr.send();
  //     }

  //     toDataURL(selectedImages, function (dataUrl) {
  //       console.log("RESULT:", dataUrl);
  //       setFile(dataUrl);
  //     });
  //   }
  // };

  //   function handleProducteditClick(e) {
  //     setShow(true)
  //     var i = localStorage.getItem("prdid");
  //     const sub = localStorage.getItem("sublength");
  //      var URL = baseurl + "adminupdateinserimages";
  //         var Request = {
  //       img:selectedImages,
  //       prdid: i,
  //       pid: i,
  //     };
  //     console.log(Request);
  //     var Headers = {};
  //     axios
  //       .post(URL, Request, Headers)
  //       .then((res) => {
  //         console.log("Updated image" + JSON.stringify(res.data));
  //         setCurrentupdate(res.data);
  //       })
  //       .catch();


  //  var url = baseurl + "adminupdateinsertmainimage";
  //     var req = {
  //       img: file1,
  //       prdid: i,
  //       pid: i,
  //     };
  //     console.log(Request);
  //     var header = {};
  //     axios
  //       .post(url, req, header)
  //       .then((res) => {
  //         setShow(false);
  //         console.log("Updated main image" + JSON.stringify(res.data));
  //         setCurrentupdate1(res.data);
  //         navigate("/Adminproductlistpage");
  //       })
  //       .catch();
  //   }
  const navigate = useNavigate();
  function handlemediaClick() {
    navigate("/Adminproductmediapage");
  }
  const handlemainclick = () => {
    navigate("/Admineditproductpage");
  };
  return (
    <>
        <div className="adminupload_outer">
          <div className="adminupload_3">
            <div className="adminupload">
              <div className="adminupload_row1">
                <div className="AdminUploadFile_body">
                  <div className="AdminUploadFile_text">
                    <label> Select Main Image (maximum 300KB allowed)</label>
                    {/* <label>(maximum 300KB allowed)</label> */}
                  </div>
                  <div className="AdminUploadFile_button">
                    <div class="wrapper">
                      <div class="btnimg"><MdUpload
                        style={{ color: "white", fontSize: "1.5rem" }}
                      /></div>
                      <input type="file"
                        accept="image/*"
                        // style={{ display: "none" }}
                        id="contained-button-file1"
                        onChange={(e) => handleUploadChange(e)}
                        multiple />
                    </div>
                    {/* <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file1"
                    onChange={(e) => handleUploadChange(e)}
                    multiple
                  />
                  <label htmlFor="contained-button-file1">
                    <Button
                      className="uploadbutton"
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ borderRadius: "50%" }}
                      // onClick={(e) => handleUploadClick()}
                    >
                      <MdUpload
                        style={{ color: "white", fontSize: "1.5rem" }}
                      />
                    </Button>
                  </label> */}
                    {/* <button type="file" onChange={handleChange}><MdUpload style={{ color: "white", fontSize: "1.5rem" }} /> </button> */}
                  </div>
                </div>
                <div className="AdminUploadFile_body">
                  <div className="AdminUploadFile_text">
                    <label> Select Images (maximum 300KB allowed)</label>
                  </div>
                  <div className="AdminUploadFile_button">
                    <div class="wrapper">
                      <div class="btnimg"><MdUpload
                        style={{ color: "white", fontSize: "1.5rem" }}
                      /></div>
                      <input type="file"
                        accept="image/*"
                        id="contained-button-file"
                        onChange={(e) => handleImagesUploadChange(e)}
                        multiple />
                    </div>
                    {/* <button type="file" onChange={handleChange}><MdUpload style={{ color: "white", fontSize: "1.5rem" }} /> </button> */}
                  </div>
                </div>
              </div>
              <div className="adminupload_row2">
                {/* <div className="adminupload_row2_inner"> */}

                {/* </div> */}
                <div className="AdminVideo_outer">
                  <div className="AdminVideo_continer">
                    <div className="AdminVideo_inner_cont">
                      <div className="AdminVideo_inner_cont_row1">
                        <div className="AdminVideo_inner_cont_row1_col1">
                          <label> Main image Review</label>
                        </div>
                      </div>
                      {/* {selectedImages1 &&
                        selectedImages1.map((image, indx) => {
                          return ( */}
                            <>
                              <div className="AdminVideo_inner_cont_row1_col2">
                                <AiFillCloseCircle
                                  style={{
                                    color: "#C3CAD9",
                                    fontsize: 50,
                                    width: 20,
                                    height: 20,
                                  }}
                                  onClick={() => {
                                    removeImage("photo");
                                  }}
                                />
                              </div>
                              <div className="AdminVideo_outer_video">
                                <img
                                  key={"1"}
                                  src={file1}
                                  className="photo"
                                  width={200}
                                ></img>

                                {/* <video src={AdminVideo_1}></video> */}
                                {/* <video autoplay="autoplay" loop="true" autoPlay muted>
                                        <source src={require("../src/Images/AdminVideo_1")} type="AdminVideo_1/mp4" />
                                            Your browser does not support HTML video.
                                </video> */}
                              </div>
                            </>
                          {/* );
                        })} */}
                    </div>
                  </div>
                </div>
                <div className="AdminImages_start">
                  <div className="AdminImages_outer_label">
                    <label>Subimages preview</label>
                    <AiFillCloseCircle
                      style={{
                        color: "#C3CAD9",
                        fontsize: 50,
                        width: 20,
                        height: 20,
                      }}
                      onClick={removeImage1}
                    />
                  </div>
                  <div className="AdminImages_outer">
                    {/* {selectedImages.length<=3} */}
                    {selectedImages &&
                      selectedImages.map((image, indx) => {
                        return (
                          <>
                            <div className="AdminImages_inner">
                              <div
                                className="AdminImages_inn_image"
                                key={image.id}
                              >
                                <img
                                  src={image.image || image}
                                  style={{ backgroundColor: "silver" }}
                                // alt="imagepreview"
                                ></img>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                {/***** */}
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
export default AdminUploadUrl;

function AdminImagesComponent({ image }) {
  return (
    <>
      <div className="AdminImages_inner">
        <div className="AdminImages_inn_image">
          <img
            src={image}
            style={{ backgroundColor: "silver" }}
            alt="imagepreview"
          ></img>
        </div>
      </div>
    </>
  );
}
