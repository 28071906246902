import AdminLeftBar_messengericon from "./Images/AdminLeftBar_messengericon.png"
import "./AdminLeftBar.css";
import { FaGlobeAmericas } from "react-icons/fa";
import { BsFillStarFill } from "react-icons/bs";
import { BsMessenger } from "react-icons/bs";
import { HiTrendingUp } from "react-icons/hi";
import { FaCompass } from "react-icons/fa";
import { BsBuilding } from "react-icons/bs";
import { AiFillPlusCircle } from "react-icons/ai";
import AdminLeftBar_humanimage from "./Images/AdminLeftBar_humanimage.jpg"
/**************************** */
import { BsCart4 } from "react-icons/bs";
import { TbBrandProducthunt } from "react-icons/tb";
import { RiDashboardFill } from "react-icons/ri";
import { CgUserList } from "react-icons/cg";
import { FaUserEdit } from "react-icons/fa";
import { MdEditNote } from "react-icons/md"; //product edit
import { RiFileEditFill } from "react-icons/ri"; //order edit
import { GiPriceTag } from "react-icons/gi";
import { RiImageEditFill } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { BiCategoryAlt } from "react-icons/bi";
import { CgStyle } from "react-icons/cg";
import { AiFillExperiment } from "react-icons/ai";
import { GiMetalBar } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

function AdminLeftBar({ handleProductlistClick,
  handleUserlistClick,
  handleProfileeditClick,
  handleDashboardClick,
  handleOrderlistClick,
  handleOrdereditClick,
  handleslidereditClick,
  handlegoldrateClick,

}) {
  const navigate = useNavigate();
  const handleCategoryClick = () => {
    navigate("/Admincategorylistpage")
  }
  const handleSubCategoryClick = () => {
    navigate("/Adminsubcategorylistpage")
  }
  const handleStyleClick = () => {
    navigate("/Adminstylelistpage")
  }

  const handleWeartypeClick = () => {
    navigate("/Adminweartypelistpage")
  }
  const handleMetalClick = () => {
    navigate("/Adminmetallistpage")
  }
  return <>
    <div className="AdminLeftBar_body">
      <div className="AdminLeftBar_top">

        <div className="AdminLeftBar_button">
          <button> <RiDashboardFill style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(h) => { handleDashboardClick() }} />    </button>
          <label className="AdminLeftBar_button_label">Dashboard</label>
        </div>
        <div className="AdminLeftBar_button"> <button > <TbBrandProducthunt style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleProductlistClick() }} /></button>
          <label className="AdminLeftBar_button_label">Product List</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <BsCart4 style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleOrderlistClick() }} />    </button>
          <label className="AdminLeftBar_button_label">Order List</label>
        </div>
        <div className="AdminLeftBar_button"> <button > <CgUserList style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(f) => { handleUserlistClick() }} /></button>
          <label className="AdminLeftBar_button_label">User List</label>
        </div>
        {/* <div className="AdminLeftBar_button"> <button> <FaUserEdit style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(g) => { handleProfileeditClick() }} /></button></div>
                <div className="AdminLeftBar_button"> <button> <MdEditNote style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e)=>{handleOrdereditClick()}}/></button></div>*/}
        <div className="AdminLeftBar_button"> <button> <RiImageEditFill style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleslidereditClick() }} /></button>
          <label className="AdminLeftBar_button_label">Banner Edit</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <GiPriceTag style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handlegoldrateClick() }} /></button>
          <label className="AdminLeftBar_button_label">Settings</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <BiCategory style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleCategoryClick() }} /></button>
          <label className="AdminLeftBar_button_label">Category List</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <BiCategoryAlt style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleSubCategoryClick() }} /></button>
          <label className="AdminLeftBar_button_label">Subcategory List</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <CgStyle style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleStyleClick() }} /></button>
          <label className="AdminLeftBar_button_label">Style List</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <AiFillExperiment style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleWeartypeClick() }} /></button>
          <label className="AdminLeftBar_button_label">Weartype List</label>
        </div>
        <div className="AdminLeftBar_button"> <button> <GiMetalBar style={{ color: "#C3CAD9", fontSize: "1rem" }} onClick={(e) => { handleMetalClick() }} /></button>
          <label className="AdminLeftBar_button_label">Metal List</label>
        </div>

      </div>

      {/* <div className="AdminLeftBar_bottom">
                <div className="AdminLeftBar_button2">  <button> <AiFillPlusCircle style={{ color: "#C3CAD9", fontSize: "1rem" }} />    </button></div>
                <div className="AdminLeftBar_bottom_image"> <img src={AdminLeftBar_humanimage} /></div>
                <div className="AdminLeftBar_bottom_image"> <img src={AdminLeftBar_humanimage} /></div>
                <div className="AdminLeftBar_bottom_image"> <img src={AdminLeftBar_humanimage} /></div>
                <div className="AdminLeftBar_bottom_image"> <img src={AdminLeftBar_humanimage} /></div>
            </div> */}
    </div>

  </>
}
export default AdminLeftBar;