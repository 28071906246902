import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminordertitle from "../Components/Adminordertitle";
import Adminsubtitle from "../Components/Adminsubtitle";
import { FaClipboardList } from "react-icons/fa";
import Adminlist from "../Components/Adminlist";
import "./Adminorderlistpage.css";
import { useNavigate } from "react-router-dom";
import Adminsubtitlelist from "../Components/Adminsubtitlelist";
import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminorderlistpage() {
  const navigate = useNavigate();
  const baseurl = config.url;
  const [orderarray, setOrderarray] = useState([]);
  const [show, setShow] = useState(false);
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    // window.location.reload();
    let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[0].selected = true;
    setTabMenus(temp);
    const url = baseurl + "adminordermenu";
    const req = { status: "All" };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        setOrderarray(res.data);
        // window.location.reload();
      })
      .catch((err) => { })
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = (e, item) => {
    e.preventDefault();
    localStorage.setItem("Id", item.cdrid);
    console.log("child id>>>" + item.cdrid);
    localStorage.setItem("headerid", item.hdrid);
    console.log("header id==>" + item.hdrid);
    localStorage.setItem("userid", item.usrid);
    console.log("user id===>" + item.usrid);
    localStorage.setItem("addressid", item.adrid);
    console.log("address id==>>" + item.adrid);

    navigate("/Adminordereditpage");
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }

  const [tabMenus, setTabMenus] = useState([
    {
      id: 1,
      name: "All",
      selected: true,
    },
    {
      id: 2,
      name: "Pending",
      selected: false,
    },
    {
      id: 3,
      name: "Processing",
      selected: false,
    },
    {
      id: 4,
      name: "Conformed",
      selected: false,
    },
    {
      id: 5,
      name: "Delivered",
      selected: false,
    },
    {
      id: 6,
      name: "Rejected",
      selected: false,
    },
  ]);

  useEffect(() => {
    setShow(true);
    const indx=localStorage.getItem("tabindx");
    if(indx){
      const menu=localStorage.getItem("tabmenu");
      let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[indx].selected = true;
    setTabMenus(temp);

    const url = baseurl + "adminordermenu";
    const req = { status: menu };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        setOrderarray(res.data);
        // window.location.reload();
      })
      .catch((err) => { })
    }else{
    var URL = baseurl + "adminorderfetch1";
    var Request = {};
    var Headers = {};
    axios.post(URL, Request, Headers)
      .then((res) => {
        setOrderarray(res.data);
        setShow(false);
      })
      .catch()
    }
  }, [])

  const onTabClicked = (menu, indx) => {
    // setShow(true);
    let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[indx].selected = true;
    setTabMenus(temp);

    const url = baseurl + "adminordermenu";
    const req = { status: menu.name };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        setOrderarray(res.data);
        // window.location.reload();
      })
      .catch((err) => { })
  }

  return (
    <>
      <div className="adminorderlist">
        <Admintopbar
          title={"Order List"}
        />
        {/* <Adminmenubar /> */}
        <div className="adminorderlist_row">
          <div className="adminorderlist_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="adminorderlist_row_col2">
            <div className="adminorderlist_row_col2_row1"></div>
            <Adminordertitle
              label1="Orders"
              icon={
                <FaClipboardList
                  style={{
                    color: "#C3CAD9",
                    fontsize: 70,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                  }}
                />
              }
              // label2="Active"
              // label3="Draft"
              // label4="Assembly"
              label5="Add Orders"
            />
            <Adminsubtitlelist
              tabMenus={tabMenus}
              setTabMenus={setTabMenus}
              onTabClicked={onTabClicked}
            />
            {/* <Adminsubtitle handleOrdereditClick={handleOrdereditClick} /> */}
            <Adminlist handleOrdereditClick={handleOrdereditClick}
              show={show}
              orderarray={orderarray}
              setOrderarray={setOrderarray}
            />
          </div>
        </div>
      </div>
    </>
  );
}
