import "../style.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Adminaddsubcategory from "../Components/Adminaddsubcategory";
import { useNavigate } from "react-router-dom";
import "./Admineditcategorypage.css";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminaddcategorypage() {
  const navigate = useNavigate();
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleOrdereditClick = () => {
    navigate("/Adminordereditpage");
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }

  return (
    <>
      <div className="admineditcategorypage">
        <div className="admineditcategorypage_col1">
          <Admintopbar
            title={"Add Subcategory"}
          />
          {/* <Adminmenubar /> */}
        </div>
        <div className="admineditcategorypage_col2">
          <div className="admineditcategorypage_col2_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            />
          </div>
          <div className="admineditcategorypage_col2_col2">

            <Adminaddsubcategory />
          </div>
        </div>
      </div>
    </>
  );
}
