import "./Admintopbar.css";
import { AiOutlineMenu } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Adminmenubar from "./Adminmenubar";
import logo from "./Images/logom.png";

function Admintopbar({ title }) {
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    localStorage.clear();
    dispatch({ type: "setUsr", payload: [] })
    window.location.href = '/';
  }
  const [isPopup, setIsPopup] = useState(false);
  const handleMobMenuClick = () => {
    setIsPopup(current => !current);
  }

  return (
    <>
      <div className="admintopbar">
        <div className="admintopbar_col1">
          <div className="admintopbar_col1_circle">
            <AiOutlineMenu style={{ color: '#D2D7E3' }} onClick={(e) => handleMobMenuClick(e)} />
            {isPopup ?
              <div className="admintopbar_col1_circle_menu">
                <Adminmenubar />
              </div> : ''}
          </div>
          <label>{title}</label>
        </div>
        <div className="admintopbar_col2">
          <div className="admintopbar_col2_icon">
            <img src={logo}/>
            {/* <FaUserCircle style={{ fontSize: '45px', color: '#D2D7E3' }} /> */}
            <div className="admintopbar_col2_logout">
              <label onClick={(e) => { handleLogoutClick(e) }}>Logout</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admintopbar;


function Menulist() {
  const navigate = useNavigate();
  const [array, setArray] = useState(["Dashboard", "Product List", "Order List", "User List", "Banner", "Settings", "Category List", "Subcategory List", "Style List", "Weartype List", "Metal List"])

  const handleMenuClick = (e, indx) => {
    console.log("menu indx==>" + indx);
    if (indx == '0') {
      navigate("/admindashboardpage");
    } else if (indx == '1') {
      navigate("/adminproductlistpage")
    } else if (indx == '2') {
      navigate("/adminorderlistpage")
    } else if (indx == '3') {
      navigate("/adminuserlistpage")
    } else if (indx == '4') {
      navigate("/adminsliderpage")
    } else if (indx == '5') {
      navigate("/admingoldratepage")
    } else if (indx == '6') {
      navigate("/admincategorylistpage")
    } else if (indx == '7') {
      navigate("/adminsubcategorylistpage")
    } else if (indx == '8') {
      navigate("/adminstylelistpage")
    } else if (indx == '9') {
      navigate("/adminweartypelistpage")
    } else if (indx == '10') {
      navigate("/adminmetallistpage")
    }
  }

  return (
    <>
      {array.map((itm, indx) => {
        return (
          <div className="Menulist">
            <label onClick={(e) => handleMenuClick(e, indx)}>{itm}</label>
          </div>
        )
      })}
    </>
  )
}