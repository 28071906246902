import React, { useEffect, useState } from "react";
import "./Adminlist.css";
import { FaPen } from "react-icons/fa";
import axios from "axios";
import Adminloader from "./Adminloader";
import config from "../config.json";

export default function Adminlist({ show,handleOrdereditClick,orderarray,setOrderarray }) {
  
  const [order, setOrder] = useState("");
  var i = localStorage.getItem("Id");

  
  return (
    <>
      <div className="adminlist">
        <div className="adminlist_header">
          <div className="adminlist_heading">
            {/* <label className="adminlist_heading_img">Image Product</label> */}
            <label className="adminlist_heading_img">ID</label>
            <label className="adminlist_heading_producer">Created Date</label>
            <label className="adminlist_heading_product">Shipped Date</label>
            <label className="adminlist_heading_id">NearON Date</label>
            <label className="adminlist_heading_cat">Delivered Date</label>
            <label className="adminlist_heading_link">Cancelled Date</label>
            <label className="adminlist_heading_cost">User Name</label>
            <label className="adminlist_heading_extra">Address</label>
            <label className="adminlist_heading_price">Status</label>
            {/* <label className="adminlist_heading_priority">Priority</label> */}
            <label className="adminlist_heading_edit">Edit</label>
          </div>

        </div>
        <div className="adminlist_middle">
        <Adminloader show={show} />
          <Singleadminlist
            orderarray={orderarray}
            handleOrdereditClick={handleOrdereditClick}
          />
        </div>
        <div className="adminlist_end">
          <button>Load More Products</button>
        </div>
      </div>
    </>
  );
}

function Singleadminlist({ orderarray, handleOrdereditClick }) {
  return (
    <>
      {
        orderarray.map((item, index) => {
          return (
            <>
              <div className="singleadminlist">
                <div className="singleadminlist_row">
                  {/* <div className="singleadminlist_row_img">
                    <input type="radio"></input>
                    <div>
                      {/* <img src={image} width={30} height={30} /> */}
                  {/* </div>
                  </div> */}
                  
                  <label className="singleadminlist_row_img">{item.hdrid}</label>
                  <label className="singleadminlist_row_producer">{item.dtcreatedon}</label>
                  <label className="singleadminlist_row_product">{item.dtshippedon}</label>
                  <label className="singleadminlist_row_id">{item.dtnearon}</label>
                  <label className="singleadminlist_row_cat">{item.dtdeliveredon}</label>
                  <label className="singleadminlist_row_link">{item.dtcancelledon}</label>
                  <label className="singleadminlist_row_cost">{item.firstname}</label>
                  <label className="singleadminlist_row_extra">{item.usraddress}</label>
                  <label className="singleadminlist_row_price">{item.status}</label>
                  {/* <span className="singleadminlist_row_priority">1</span> */}
                  <FaPen className="singleadminlist_row_edit" style={{ color: '#D2D7E3' }} onClick={(e) => { handleOrdereditClick(e,item) }} />
                </div>
              </div>
            </>
          )
        })
      }

    </>
  );
}
