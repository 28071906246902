import "./Admincategorylistpage.css";
import Admintopbar from "../Components/Admintopbar";
import AdminLeftBar from "../Components/AdminLeftBar";
import Admincategorylisttitle from "../Components/Admincategorylisttiltle";
import Adminsubtitle from "../Components/Adminsubtitle";
import { FaClipboardList } from "react-icons/fa";
import Adminsubcategory from "../Components/Adminsubcategorylist";
import { useNavigate } from "react-router-dom";
import Adminsubcategorylist from "../Components/Adminsubcategorylist";
import React, { useState, useEffect } from "react";
import Adminsubtitlelist from "../Components/Adminsubtitlelist";
import Adminloader from "../Components/Adminloader";
import axios from "axios";
import config from "../config.json";
import Adminmenubar from "../Components/Adminmenubar";

export default function Adminsubcategorylistpage() {
  const navigate = useNavigate();
  const handleProductlistClick = () => {
    navigate("/Adminproductlistpage");
  };
  const handleUserlistClick = () => {
    navigate("/Adminuserlistpage");
  };
  const handleProfileeditClick = () => {
    navigate("/Adminprofileeditpage");
  };
  const handleDashboardClick = () => {
    navigate("/Admindashboardpage");
  };
  const handleOrderlistClick = () => {
    navigate("/Adminorderlistpage");
  };
  const handleSubategoryEditClick = (e, itm) => {
    e.preventDefault();
    localStorage.setItem("subcategoryid", itm.id);
    navigate("/admineditsubcategorypage")
  };
  const handleProducteditClick = () => {
    navigate("/Admineditproductpage");
  };
  const handlegoldrateClick = () => {
    navigate("/Admingoldratepage");
  };
  const handleslidereditClick = () => {
    navigate("/Adminsliderpage");
  }
  const handleAddSubcategoryClick = () => {
    navigate("/Adminaddsubcategorypage");
  }

  const [tabMenus, setTabMenus] = useState([
    {
      id: 1,
      name: "All",
      selected: true,
    },
    {
      id: 2,
      name: "Active",
      selected: false,
    },
    {
      id: 3,
      name: "InActive",
      selected: false,
    },
  ]);

  const onTabClicked = (menu, indx) => {
    setShow(true)
    let temp = [...tabMenus]
    for (const iterator of temp) {
      iterator.selected = false;
    }
    temp[indx].selected = true;
    setTabMenus(temp);

    const url = baseurl + "adminsubcategorymenu";
    const req = { status: menu.name };
    const header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false)
        setsubcategory(res.data);
        console.log("menu name==>" + JSON.stringify(res.data));
        // window.location.reload();
      })
      .catch((err) => { })
  }

  const baseurl = config.url;
  const [subcategory, setsubcategory] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true)
    var url = baseurl + "adminfetchsubcategory";
    var req = {};
    var header = {};
    axios
      .post(url, req, header)
      .then((res) => {
        setShow(false);
        setsubcategory(res.data);
      })
      .catch();
  }, [])

  return (
    <>
      <div className="adminorderlist">
        <Admintopbar
          title={"Subcategory List"}
        />
        {/* <Adminmenubar /> */}
        <div className="adminorderlist_row">
          <div className="adminorderlist_row_col1">
            <AdminLeftBar
              handleProductlistClick={handleProductlistClick}
              handleUserlistClick={handleUserlistClick}
              handleProfileeditClick={handleProfileeditClick}
              handleDashboardClick={handleDashboardClick}
              handleOrderlistClick={handleOrderlistClick}
              //   handleOrdereditClick={handleOrdereditClick}
              handleProducteditClick={handleProducteditClick}
              handlegoldrateClick={handlegoldrateClick}
              handleslidereditClick={handleslidereditClick}
            //   handleCategoryClick={handleCategoryClick}
            />
          </div>
          <div className="admincategorylistpage_row_col2">
            <div className="admincategorylistpage_row_col2_inner">
              <Admincategorylisttitle
                label1="Subcategory List"
                icon={
                  <FaClipboardList
                    style={{
                      color: "#C3CAD9",
                      fontsize: 70,
                      width: 20,
                      height: 20,
                      textAlign: "center",
                    }}
                  />
                }
                // label2="Active"
                // label3="Draft"
                // label4="Assembly"
                label5="Add Subcategory"
                handleAddproductClick={handleAddSubcategoryClick}
              />
            </div>
            <Adminsubtitlelist
              tabMenus={tabMenus}
              setTabMenus={setTabMenus}
              onTabClicked={onTabClicked}
            />
            <div className="admincategorylistpage_loader">
              <Adminloader show={show} />
            </div>
            {/* <Adminsubtitle handleOrdereditClick={handleOrdereditClick} /> */}
            <Adminsubcategorylist handleSubategoryEditClick={handleSubategoryEditClick}
              subcategory={subcategory}
            />
          </div>
        </div>
      </div>
    </>
  );
}
