import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import "./Admincategorylist.css";
import config from "../config.json";
import axios from "axios";
import Adminloader from "./Adminloader";

export default function Admincategorylist({ handleCategoryEditClick, category, show }) {

    return (
        <>
            <div className="admincategorylist">
                <div className="admincategorylist_row1">
                    <label className="admincategorylist_row1_col1">ID</label>
                    <label className="admincategorylist_row1_col2">Category</label>
                    <label className="admincategorylist_row1_col4">Sequence Number</label>
                    <label className="admincategorylist_row1_col3">Edit</label>
                </div>
                {/* <Adminloader show={show}/> */}
                {category.map((itm, indx) => (
                    <div className="admincategorylist_row2">
                        {/* <div className="admincategorylist_row2_inner"> */}
                        <label className="admincategorylist_row2_col1">{itm.id}</label>
                        <label className="admincategorylist_row2_col2">{itm.categoryname}</label>
                        <label className="admincategorylist_row2_col3">{itm.sequenceno}</label>
                        <FaPen className="singleadminlist_row_edit" style={{ color: '#D2D7E3' }} onClick={(e) => { handleCategoryEditClick(e, itm) }} />
                        {/* </div> */}
                    </div>
                ))}
            </div>

        </>
    )
}